/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react-dom";
import { TableColumn } from "components/table/table.component";
import { Link } from "react-router-dom";
import { AccessorFn } from "@tanstack/react-table";
import _ from "lodash";
import { StreetViewFragment } from "graphql/hasura/types-and-hooks";

export type IndividualTableSchema = Omit<StreetViewFragment, "__typename">;
export interface IndividualRegistrationTableFilters {
  businessName: string;
  email: string;
  firstName: string;
  phoneNumber: string;
  surname: string;
}

export type PropertyTableSchema = {
  Buildingaream2: string;
  Buildingcentroidgps: string;
  Buildingtype: string;
  Businessname: string;
  LastUpdated: string;
  Latitude: string;
  Longitude: string;
  Numberofstoreys: string;
  Phone1: string;
  Phone2: string;
  Pluscode1: string;
  Pluscode2: string;
  Roofmaterial: string;
  Settlement: string;
  Street: string;
  Uuid: string;
  Wallmaterial: string;
  WhoUpdated: string;
};

export const INDIVIDUAL_TABLE_SCHEMA_DATA_TYPE_MAP: Record<
  keyof IndividualTableSchema,
  "string" | "number" | "Date"
> = {
  Buildingaream2: "string",
  Buildingcentroidgps: "string",
  Buildingtype: "string",
  Businessname: "string",
  LastUpdated: "string",
  Latitude: "string",
  Longitude: "string",
  Numberofstoreys: "string",
  Phone1: "string",
  Phone2: "string",
  Pluscode1: "string",
  Pluscode2: "string",
  Roofmaterial: "string",
  Settlement: "string",
  Street: "string",
  Uuid: "string",
  Wallmaterial: "string",
  WhoUpdated: "string",
};
export const DEFAULT_FILTER_ACCESSOR: Record<
  keyof IndividualRegistrationTableFilters,
  string
> = {
  businessName: "BsnsName",
  email: "EmailId",
  firstName: "FirstName",
  phoneNumber: "MobileNumber",
  surname: "SurName",
};

export const getAccessorType = (
  accessor:
    | keyof IndividualTableSchema
    | AccessorFn<IndividualTableSchema>
    | undefined,
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return INDIVIDUAL_TABLE_SCHEMA_DATA_TYPE_MAP[accessor];
};

export const propertiesTableColumns: TableColumn<
  IndividualTableSchema | Record<string, unknown>
>[] = [
  {
    header: "UUID",
    accessorKey: "Uuid",
    align: "center",
    cell: ({
      getValue,
      row: {
        original: { Uuid },
      },
    }) => {
      return (
        <Link to={`/properties/${Uuid}/details`} color="inherit">
          {/* @ts-ignore*/}
          <> {Uuid}</>
        </Link>
      );
    },
  },
  {
    header: "Building Area",
    accessorKey: "Buildingaream2",
    align: "left",
    format: "string",
  },
  {
    header: "Building Centroid GPS",
    accessorKey: "Buildingcentroidgps",
    align: "left",
    format: "string",
  },
  {
    header: "Building Type",
    accessorKey: "Buildingtype",
    align: "left",
    format: "string",
  },
  {
    header: "Business Name",
    accessorKey: "Businessname",
    align: "left",
    format: "string",
  },
  {
    header: "Latitude",
    accessorKey: "Latitude",
    align: "left",
    format: "string",
  },
  {
    header: "Longitude",
    accessorKey: "Longitude",
    align: "left",
    format: "string",
  },
  {
    header: "Number of Storeys",
    accessorKey: "Numberofstoreys",
    align: "left",
    format: "string",
  },
  {
    header: "Phone 1",
    accessorKey: "Phone1",
    align: "left",
    format: "string",
  },
  {
    header: "Phone 2",
    accessorKey: "Phone2",
    align: "left",
    format: "string",
  },
  {
    header: "Plus Code 1",
    accessorKey: "Pluscode1",
    align: "left",
    format: "string",
  },
  {
    header: "Plus Code 2",
    accessorKey: "Pluscode2",
    align: "left",
    format: "string",
  },
  {
    header: "Roof Material",
    accessorKey: "Roofmaterial",
    align: "left",
    format: "string",
  },
  {
    header: "Settlement",
    accessorKey: "Settlement",
    align: "left",
    format: "string",
  },
  {
    header: "Street",
    accessorKey: "Street",
    align: "left",
    format: "string",
  },
];
