/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/style-prop-object */
import { Link } from "@mui/material";
import React from "react";

import {
  FormattedNumber,
  FormatNumberOptions,
  FormattedNumberParts,
} from "react-intl";
import {
  createDateTimeString,
  getFormattedDate,
} from "utils/internationalization";

export const FORMAT_VALUE_OPTIONS = {
  number: "number",
  currency: "currency",
  date: "date",
  dateTime: "dateTime",
  percent: "percent",
  area: "area",
  phone: "phone",
  coordinate: "coordinate",
  string: "string",
  maplink: "maplink",
};

type FormatValueType = keyof typeof FORMAT_VALUE_OPTIONS;

interface FormattedValueProps {
  format: FormatValueType;
  value: string | number | Date | undefined;
}

const SQUARE_METERS = "sq. meters"; // Or

const FORMAT_CURRENCY_OPTIONS: FormatNumberOptions = {
  style: "currency",
  currency: "UGX",
  maximumFractionDigits: 0,
};

const FORMAT_NUMBER_OPTIONS: FormatNumberOptions = {
  style: "decimal",
  maximumFractionDigits: 0,
};

const FORMAT_COORDINATE_OPTIONS: FormatNumberOptions = {
  style: "decimal",
  maximumFractionDigits: 8,
};

const FORMAT_PERCENT_OPTIONS: FormatNumberOptions = {
  style: "percent",
  maximumFractionDigits: 1,
};

export const FORMAT_DATE_TIME_OPTIONS: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "long",
  day: "2-digit",
  hour: "numeric",
  minute: "numeric",
};

export const FORMAT_DATE_OPTIONS: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
};

const buildCurrencyParts = (parts: Intl.NumberFormatPart[]) => {
  const obj: Record<string, string> = {};
  let str = "";
  parts.forEach((part) => {
    if (!(part.type === "minusSign" || part.type === "currency"))
      str += part.value;

    obj[part.type] = part.value;
  });

  obj.value = str.trim();
  return obj;
};

const FormattedValue: React.FC<FormattedValueProps> = (props) => {
  const { format, value } = props;
  if (!value || value === "") return <></>;
  switch (format) {
    case FORMAT_VALUE_OPTIONS.currency:
      // @ts-ignore
      if (value < 0) {
        return (
          <FormattedNumberParts
            style="currency"
            currency="UGX"
            value={value as number}
          >
            {(parts) => {
              const {
                currency,
                minusSign,
                value: val,
              } = buildCurrencyParts(parts);
              return (
                <>
                  {currency} {minusSign}
                  {val}
                </>
              );
            }}
          </FormattedNumberParts>
        );
      }
      return (
        <FormattedNumber {...FORMAT_CURRENCY_OPTIONS} value={value as number} />
      );

    case FORMAT_VALUE_OPTIONS.number:
      return (
        <FormattedNumber {...FORMAT_NUMBER_OPTIONS} value={value as number} />
      );

    case FORMAT_VALUE_OPTIONS.coordinate:
      return (
        <FormattedNumber
          {...FORMAT_COORDINATE_OPTIONS}
          value={value as number}
        />
      );

    case FORMAT_VALUE_OPTIONS.dateTime:
      return <>{createDateTimeString(value as string)}</>;

    case FORMAT_VALUE_OPTIONS.percent:
      return (
        <FormattedNumber {...FORMAT_PERCENT_OPTIONS} value={value as number} />
      );

    case FORMAT_VALUE_OPTIONS.area:
      return (
        // @ts-ignore
        <>
          {value} {SQUARE_METERS}
        </>
      );

    case FORMAT_VALUE_OPTIONS.phone:
      // @ts-ignore
      return <>{value}</>;

    case FORMAT_VALUE_OPTIONS.date:
      if (value === "0") {
        return <>{value}</>;
      }

      return <>{getFormattedDate(value as string)}</>;

    case FORMAT_VALUE_OPTIONS.maplink:
      return (
        <Link
          href={`https://www.google.com/maps/search/?api=1&query=${value}`}
          target="_blank"
          rel="noopener noreferrer"
          underline="none"
        >
          {` ${value} `}
        </Link>
      );

    default:
      // @ts-ignore
      return <>{value}</>;
  }
};

export default FormattedValue;
