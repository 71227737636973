/* eslint-disable import/no-extraneous-dependencies */
// Dependencies
import {
  ApolloClient,
  createHttpLink,
  ApolloLink,
  from,
  ServerParseError,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";

// Utils
import auth from "graphql/authentication";

// Config
import GlobalConfig from "global.config";

// Apollo
import cache from "./cache";

// Assets
import packageJson from "../../package.json";
import { authenticationDataVar } from "./authentication.utils";

export type GraphQLServer = "RBAC" | "HASURA";

export const CLIENT_NAME: Record<GraphQLServer, string> = {
  RBAC: "RBAC client",
  HASURA: "HASURA client",
};

// Only for Development stage: You should disable CORS policy in web browsers,
// if you have CORS issues.
const httpLink = createUploadLink({
  uri: GlobalConfig.get("GraphQLServerURL"),
  credentials: "include",
});

const httpPrivateLink = createUploadLink({
  uri: GlobalConfig.get("GraphQLServerPrivateURL"),
  credentials: "include",
});

const authLink = setContext((_, previousContext) => {
  const { headers, noAuthNeeded } = previousContext;
  const { token } = authenticationDataVar();

  if (noAuthNeeded || !token) return previousContext;

  // return the headers to the context so httpLink can read them
  return {
    ...previousContext,
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
      "x-hasura-role": "user",
      "x-hasura-admin-secret": `s93EniSI_HEKUc3$9e-r`,
      "x-hasura-user-id": `sysadmin@ripplenami.com`,
    },
  };
});

const hasuraHttpLink = createHttpLink({
  uri: GlobalConfig.get("HasuraGraphQLServerURL"),
  credentials: "include",
});

const errorLink = onError(({ networkError }) => {
  if (networkError) {
    const serverParseError = networkError as ServerParseError;
    const statusCode = serverParseError.statusCode ?? 0;
    // WARNING: Make sure CORS headers are passing for the 401 "Unauthorized" error response
    // so that the JS error object is not empty. E.g. If CORS headers are added in the nginx
    // config, make sure the add_header directive is using the "always" option.
    if (statusCode === 401) {
      auth.logOut();
    }
  }
});

const version = packageJson.version;

const client = new ApolloClient({
  name: "rtcs-webui",
  version,
  link: from([
    errorLink,
    ApolloLink.split(
      (operation) => operation.getContext().clientName === CLIENT_NAME.HASURA,
      authLink.concat(hasuraHttpLink),
      authLink.concat(
        ApolloLink.split(
          (operation) =>
            operation.getContext().headers?.Authorization === undefined,
          httpLink,
          httpPrivateLink,
        ),
      ),
    ),
  ]),
  cache,
});

export default client;
