import { Rtcs_Gm_Dev_Aggregate_Result_Txt } from "./types-and-hooks";

/** jsonToStringQueryVariables converts json object to string so that
 * the ACFs can build properly the SQL queries. We have to do this to
 * improve the ACFs' performance.
 */
export const jsonToStringQueryVariables = (
  jsonObj: Record<string, unknown>,
): string => {
  const variables = { variables: jsonObj };
  return JSON.stringify(variables);
};

export const isValidTin = (tin: string): boolean =>
  `${tin}`?.length === 10 && `${tin}`.startsWith("1");

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const aggregateArrayToObject = (
  aggArray:
    | Pick<Rtcs_Gm_Dev_Aggregate_Result_Txt, "operation" | "value">[]
    | undefined,
) => {
  const mapped = aggArray?.map((item) => ({
    [item.operation as string]: item.value,
  }));

  return mapped ? Object.assign({}, ...mapped) : null;
};

export default {
  jsonToStringQueryVariables,
  aggregateArrayToObject,
};
