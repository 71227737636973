export type ConfigVariable =
  | "GraphQLServerURL"
  | "GraphQLServerPrivateURL"
  | "HasuraGraphQLServerURL"
  | "HasuraUser"
  | "HasuraAdminSecret";

const {
  REACT_APP_GRAPHQL_SERVER_URL,
  REACT_APP_GRAPHQL_SERVER_PRIVATE_URL,
  REACT_APP_HASURA_GRAPHQL_SERVER_URL,
  REACT_APP_HASURA_GRAPHQL_ADMIN_SECRET,
  REACT_APP_HASURA_USER_ID,
} = process.env;

class GlobalConfig {
  private static variables: {
    [x in ConfigVariable]?: string;
  } = {};

  static get(variable: ConfigVariable): string | undefined {
    const { variables } = GlobalConfig;

    if (!variables[variable]) {
      switch (variable) {
        case "GraphQLServerURL":
          variables[variable] = REACT_APP_GRAPHQL_SERVER_URL;
          break;

        case "GraphQLServerPrivateURL":
          variables[variable] = REACT_APP_GRAPHQL_SERVER_PRIVATE_URL;
          break;

        case "HasuraGraphQLServerURL":
          variables[variable] = REACT_APP_HASURA_GRAPHQL_SERVER_URL;
          break;

        case "HasuraUser":
          variables[variable] = REACT_APP_HASURA_USER_ID;
          break;

        case "HasuraAdminSecret":
          variables[variable] = REACT_APP_HASURA_GRAPHQL_ADMIN_SECRET;
          break;

        default:
          throw new Error("Environment variable not implemented");
      }
    }

    return variables[variable];
  }
}

export default GlobalConfig;
