// file deepcode ignore GlobalReplacementRegex: <no need to apply regex yet simplier functions can achieve what we want>
import React from "react";
import { useLocation, useMatch, NavLink } from "react-router-dom";

// Styles
import SC from "./breadcrumb.styles";

export interface RoutesProps {
  index: number;
  text: string;
  path: string;
}
export interface BreadcrumbProps {
  label?: string;
  // send this variable to specify simple paths that do not contain id
  isSimple?: boolean;
  staticRoutes?: Array<RoutesProps>;
}

const getRouteUrl = (arr: string[], index: number) => {
  const newArr: string[] = [];
  for (let i = 0; i < arr.length; i += 1) {
    newArr.push(arr[i]);
    if (i === index) {
      break;
    }
  }
  if (index === 1 && arr[0] !== "reports") {
    newArr.push(arr[index + 1]);
  }
  return `/${newArr.join("/")}`;
};

export const Breadcrumb: React.FC<BreadcrumbProps> = ({
  label,
  isSimple,
  staticRoutes,
}: BreadcrumbProps) => {
  const [routes, setRoutes] = React.useState<RoutesProps[]>([]);
  const location = useLocation();
  const match = useMatch(location.pathname);

  const { pathname } = location;

  React.useEffect(() => {
    if (staticRoutes === undefined) {
      const routesArr: RoutesProps[] = [];
      // convert the path name to array and also remove the first element since it's always empty when slicing pathname
      const pathArr = pathname.split("/").slice(1);

      for (let i = 0; i < pathArr.length; i += 1) {
        const route = {
          index: i,
          path: getRouteUrl(pathArr, i),
          text: pathArr[i].replaceAll("-", " "),
        } as RoutesProps;

        if (i === 1 && label !== undefined) {
          route.text = label;
        }

        routesArr.push(route);

        if (pathArr.length === 3) {
          if (i === 1 && pathArr[0] !== "reports") {
            break;
          }
        }
      }

      // change the routes state to reflect the routeArr (array)
      // stores routes in a state for the whole page to bring up the breadcrumb navigation.
      setRoutes(routesArr);
    } else {
      setRoutes(staticRoutes);
    }
  }, [pathname, label, staticRoutes]);

  const pathClean = match?.pattern.path.replace("-", " ");
  let pathSections;
  if (isSimple) {
    pathSections = pathClean?.split("/");
  }
  return (
    <SC.Container px={2} py={1}>
      {isSimple ? (
        <SC.Breadcrumbs separator={<SC.NavigateNextIcon fontSize="small" />}>
          {pathSections?.map(
            (item, index) =>
              item !== "" && (
                <SC.LinkSimple
                  underline="none"
                  key={item}
                  color={
                    pathSections.length - 1 === index ? "inherit" : "primary"
                  }
                >
                  {item}
                </SC.LinkSimple>
              ),
          )}
        </SC.Breadcrumbs>
      ) : (
        <SC.Breadcrumbs separator={<SC.NavigateNextIcon fontSize="small" />}>
          {routes?.map(
            (item: { index: number; text: string; path: string }) => (
              <SC.Link
                to={routes.length - 1 !== item.index ? item.path : "#"}
                key={item.index}
                isActive={routes.length - 1 === item.index}
              >
                {item.text}
              </SC.Link>
            ),
          )}
        </SC.Breadcrumbs>
      )}
    </SC.Container>
  );
};

export default Breadcrumb;
