/* eslint-disable @typescript-eslint/ban-ts-comment */
// Dependencies
import React from "react";

// Components
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
//import TreeItem from "@mui/lab/TreeItem";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import SelectableListItem from "components/selectable-list-item/selectable-list-item.component";

// Assets
import SC from "./tree-view-list.styles";

export interface TreeViewListProps<T extends Record<string, unknown>> {
  items?: T[];
  isEditable: boolean;
  getItemKey: (item: T) => string;
  getItemLabel: (item: T) => string;
  getItemTitle?: (item: T) => string;
  getItemChecked: (item: T) => boolean;
  getItemChildren: (item: T) => T[] | undefined;
  onChange: (selectedValue: string) => void;
}

export const TreeViewList = <T extends Record<string, unknown>>({
  items = [],
  isEditable,
  getItemKey,
  getItemLabel,
  getItemTitle,
  getItemChecked,
  getItemChildren,
  onChange,
}: TreeViewListProps<T>): JSX.Element => {
  const handleOnClick: React.MouseEventHandler<HTMLLIElement> &
    React.MouseEventHandler<HTMLButtonElement> = (
    event:
      | React.MouseEvent<HTMLLIElement, MouseEvent>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    onChange(event.currentTarget.id);
  };
  const createChildStructure = (item: T): JSX.Element => {
    const key = getItemKey(item);
    const label = getItemLabel(item);
    const title = getItemTitle?.(item);
    const checked = getItemChecked(item);
    const children = getItemChildren(item);

    return (
      <TreeItem
        itemId={key}
        key={key}
        label={
          <SelectableListItem
            id={key}
            label={label}
            title={title}
            checked={checked}
            isEditable={isEditable}
            isTreeItem
            onClick={handleOnClick}
          />
        }
        // eslint-disable-next-line react/no-children-prop
        children={children?.map((child) => createChildStructure(child))}
      />
    );
  };

  return (
    <SC.Box>
      <SC.TreeViewMui
      //defaultCollapseIcon={<ExpandMoreIcon />}
      //defaultExpandIcon={<ChevronRightIcon />}
      >
        {items.map((item) => createChildStructure(item))}
      </SC.TreeViewMui>
    </SC.Box>
  );
};

export default TreeViewList;
