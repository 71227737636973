import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  bigint: { input: any; output: any; }
  float8: { input: any; output: any; }
  timestamptz: { input: any; output: any; }
};

/** expression to compare columns of type Boolean. All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

/** expression to compare columns of type String. All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  _nlike?: InputMaybe<Scalars['String']['input']>;
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  _similar?: InputMaybe<Scalars['String']['input']>;
};

/** expression to compare columns of type bigint. All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']['input']>;
  _gt?: InputMaybe<Scalars['bigint']['input']>;
  _gte?: InputMaybe<Scalars['bigint']['input']>;
  _in?: InputMaybe<Array<Scalars['bigint']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['bigint']['input']>;
  _lte?: InputMaybe<Scalars['bigint']['input']>;
  _neq?: InputMaybe<Scalars['bigint']['input']>;
  _nin?: InputMaybe<Array<Scalars['bigint']['input']>>;
};

/** expression to compare columns of type float8. All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']['input']>;
  _gt?: InputMaybe<Scalars['float8']['input']>;
  _gte?: InputMaybe<Scalars['float8']['input']>;
  _in?: InputMaybe<Array<Scalars['float8']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['float8']['input']>;
  _lte?: InputMaybe<Scalars['float8']['input']>;
  _neq?: InputMaybe<Scalars['float8']['input']>;
  _nin?: InputMaybe<Array<Scalars['float8']['input']>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "rtcs_gm_dev.PH_SYS_Users" */
  delete_rtcs_gm_dev_PH_SYS_Users?: Maybe<Rtcs_Gm_Dev_Ph_Sys_Users_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.PH_rtcs_Country" */
  delete_rtcs_gm_dev_PH_rtcs_Country?: Maybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
  delete_rtcs_gm_dev_PH_streetview_StreetViewAnalysis?: Maybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.SYS_Users" */
  delete_rtcs_gm_dev_SYS_Users?: Maybe<Rtcs_Gm_Dev_Sys_Users_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.aggregate_result" */
  delete_rtcs_gm_dev_aggregate_result?: Maybe<Rtcs_Gm_Dev_Aggregate_Result_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.aggregate_result_txt" */
  delete_rtcs_gm_dev_aggregate_result_txt?: Maybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.rtcs_Country" */
  delete_rtcs_gm_dev_rtcs_Country?: Maybe<Rtcs_Gm_Dev_Rtcs_Country_Mutation_Response>;
  /** delete data from the table: "rtcs_gm_dev.streetview_StreetViewAnalysis" */
  delete_rtcs_gm_dev_streetview_StreetViewAnalysis?: Maybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Mutation_Response>;
  /** insert data into the table: "rtcs_gm_dev.PH_SYS_Users" */
  insert_rtcs_gm_dev_PH_SYS_Users?: Maybe<Rtcs_Gm_Dev_Ph_Sys_Users_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.PH_SYS_Users" */
  insert_rtcs_gm_dev_PH_SYS_Users_one?: Maybe<Rtcs_Gm_Dev_Ph_Sys_Users>;
  /** insert data into the table: "rtcs_gm_dev.PH_rtcs_Country" */
  insert_rtcs_gm_dev_PH_rtcs_Country?: Maybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.PH_rtcs_Country" */
  insert_rtcs_gm_dev_PH_rtcs_Country_one?: Maybe<Rtcs_Gm_Dev_Ph_Rtcs_Country>;
  /** insert data into the table: "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
  insert_rtcs_gm_dev_PH_streetview_StreetViewAnalysis?: Maybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
  insert_rtcs_gm_dev_PH_streetview_StreetViewAnalysis_one?: Maybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis>;
  /** insert data into the table: "rtcs_gm_dev.SYS_Users" */
  insert_rtcs_gm_dev_SYS_Users?: Maybe<Rtcs_Gm_Dev_Sys_Users_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.SYS_Users" */
  insert_rtcs_gm_dev_SYS_Users_one?: Maybe<Rtcs_Gm_Dev_Sys_Users>;
  /** insert data into the table: "rtcs_gm_dev.aggregate_result" */
  insert_rtcs_gm_dev_aggregate_result?: Maybe<Rtcs_Gm_Dev_Aggregate_Result_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.aggregate_result" */
  insert_rtcs_gm_dev_aggregate_result_one?: Maybe<Rtcs_Gm_Dev_Aggregate_Result>;
  /** insert data into the table: "rtcs_gm_dev.aggregate_result_txt" */
  insert_rtcs_gm_dev_aggregate_result_txt?: Maybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.aggregate_result_txt" */
  insert_rtcs_gm_dev_aggregate_result_txt_one?: Maybe<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** insert data into the table: "rtcs_gm_dev.rtcs_Country" */
  insert_rtcs_gm_dev_rtcs_Country?: Maybe<Rtcs_Gm_Dev_Rtcs_Country_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.rtcs_Country" */
  insert_rtcs_gm_dev_rtcs_Country_one?: Maybe<Rtcs_Gm_Dev_Rtcs_Country>;
  /** insert data into the table: "rtcs_gm_dev.streetview_StreetViewAnalysis" */
  insert_rtcs_gm_dev_streetview_StreetViewAnalysis?: Maybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Mutation_Response>;
  /** insert a single row into the table: "rtcs_gm_dev.streetview_StreetViewAnalysis" */
  insert_rtcs_gm_dev_streetview_StreetViewAnalysis_one?: Maybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis>;
  /** update data of the table: "rtcs_gm_dev.PH_SYS_Users" */
  update_rtcs_gm_dev_PH_SYS_Users?: Maybe<Rtcs_Gm_Dev_Ph_Sys_Users_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.PH_rtcs_Country" */
  update_rtcs_gm_dev_PH_rtcs_Country?: Maybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
  update_rtcs_gm_dev_PH_streetview_StreetViewAnalysis?: Maybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.SYS_Users" */
  update_rtcs_gm_dev_SYS_Users?: Maybe<Rtcs_Gm_Dev_Sys_Users_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.aggregate_result" */
  update_rtcs_gm_dev_aggregate_result?: Maybe<Rtcs_Gm_Dev_Aggregate_Result_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.aggregate_result_txt" */
  update_rtcs_gm_dev_aggregate_result_txt?: Maybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.rtcs_Country" */
  update_rtcs_gm_dev_rtcs_Country?: Maybe<Rtcs_Gm_Dev_Rtcs_Country_Mutation_Response>;
  /** update data of the table: "rtcs_gm_dev.streetview_StreetViewAnalysis" */
  update_rtcs_gm_dev_streetview_StreetViewAnalysis?: Maybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Mutation_Response>;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Ph_Sys_UsersArgs = {
  where: Rtcs_Gm_Dev_Ph_Sys_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Ph_Rtcs_CountryArgs = {
  where: Rtcs_Gm_Dev_Ph_Rtcs_Country_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysisArgs = {
  where: Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Sys_UsersArgs = {
  where: Rtcs_Gm_Dev_Sys_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Aggregate_ResultArgs = {
  where: Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Aggregate_Result_TxtArgs = {
  where: Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Rtcs_CountryArgs = {
  where: Rtcs_Gm_Dev_Rtcs_Country_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rtcs_Gm_Dev_Streetview_StreetViewAnalysisArgs = {
  where: Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Bool_Exp;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Sys_UsersArgs = {
  objects: Array<Rtcs_Gm_Dev_Ph_Sys_Users_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Sys_Users_OneArgs = {
  object: Rtcs_Gm_Dev_Ph_Sys_Users_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Rtcs_CountryArgs = {
  objects: Array<Rtcs_Gm_Dev_Ph_Rtcs_Country_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Rtcs_Country_OneArgs = {
  object: Rtcs_Gm_Dev_Ph_Rtcs_Country_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysisArgs = {
  objects: Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_OneArgs = {
  object: Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Sys_UsersArgs = {
  objects: Array<Rtcs_Gm_Dev_Sys_Users_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Sys_Users_OneArgs = {
  object: Rtcs_Gm_Dev_Sys_Users_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Aggregate_ResultArgs = {
  objects: Array<Rtcs_Gm_Dev_Aggregate_Result_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Aggregate_Result_OneArgs = {
  object: Rtcs_Gm_Dev_Aggregate_Result_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Aggregate_Result_TxtArgs = {
  objects: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Aggregate_Result_Txt_OneArgs = {
  object: Rtcs_Gm_Dev_Aggregate_Result_Txt_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Rtcs_CountryArgs = {
  objects: Array<Rtcs_Gm_Dev_Rtcs_Country_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Rtcs_Country_OneArgs = {
  object: Rtcs_Gm_Dev_Rtcs_Country_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Streetview_StreetViewAnalysisArgs = {
  objects: Array<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_OneArgs = {
  object: Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Insert_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Ph_Sys_UsersArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Ph_Sys_Users_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Ph_Sys_Users_Set_Input>;
  where: Rtcs_Gm_Dev_Ph_Sys_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Ph_Rtcs_CountryArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Set_Input>;
  where: Rtcs_Gm_Dev_Ph_Rtcs_Country_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysisArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Set_Input>;
  where: Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Sys_UsersArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Sys_Users_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Sys_Users_Set_Input>;
  where: Rtcs_Gm_Dev_Sys_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Aggregate_ResultArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Set_Input>;
  where: Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Aggregate_Result_TxtArgs = {
  _set?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Set_Input>;
  where: Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Rtcs_CountryArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Rtcs_Country_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Rtcs_Country_Set_Input>;
  where: Rtcs_Gm_Dev_Rtcs_Country_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rtcs_Gm_Dev_Streetview_StreetViewAnalysisArgs = {
  _inc?: InputMaybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Inc_Input>;
  _set?: InputMaybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Set_Input>;
  where: Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Bool_Exp;
};

/** column ordering options */
export enum Order_By {
  /** in the ascending order, nulls last */
  Asc = 'asc',
  /** in the ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in the ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in the descending order, nulls first */
  Desc = 'desc',
  /** in the descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in the descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** query root */
export type Query_Root = {
  __typename?: 'query_root';
  /** execute function "rtcs_gm_dev.FnSYS_Users" which returns "rtcs_gm_dev.PH_SYS_Users" */
  rtcs_gm_dev_FnSYS_Users: Array<Rtcs_Gm_Dev_Ph_Sys_Users>;
  /** execute function "rtcs_gm_dev.FnSYS_Users" and query aggregates on result of table type "rtcs_gm_dev.PH_SYS_Users" */
  rtcs_gm_dev_FnSYS_Users_aggregate: Rtcs_Gm_Dev_Ph_Sys_Users_Aggregate;
  /** execute function "rtcs_gm_dev.FnSYS_Users_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_FnSYS_Users_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.FnSYS_Users_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_FnSYS_Users_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.FnSYS_Users_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_FnSYS_Users_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.FnSYS_Users_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_FnSYS_Users_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fnrtcs_Country" which returns "rtcs_gm_dev.PH_rtcs_Country" */
  rtcs_gm_dev_Fnrtcs_Country: Array<Rtcs_Gm_Dev_Ph_Rtcs_Country>;
  /** execute function "rtcs_gm_dev.Fnrtcs_Country" and query aggregates on result of table type "rtcs_gm_dev.PH_rtcs_Country" */
  rtcs_gm_dev_Fnrtcs_Country_aggregate: Rtcs_Gm_Dev_Ph_Rtcs_Country_Aggregate;
  /** execute function "rtcs_gm_dev.Fnrtcs_Country_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnrtcs_Country_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fnrtcs_Country_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnrtcs_Country_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fnrtcs_Country_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnrtcs_Country_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fnrtcs_Country_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnrtcs_Country_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fnstreetview_StreetViewAnalysis" which returns "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
  rtcs_gm_dev_Fnstreetview_StreetViewAnalysis: Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis>;
  /** execute function "rtcs_gm_dev.Fnstreetview_StreetViewAnalysis" and query aggregates on result of table type "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
  rtcs_gm_dev_Fnstreetview_StreetViewAnalysis_aggregate: Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Aggregate;
  /** execute function "rtcs_gm_dev.Fnstreetview_StreetViewAnalysis_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnstreetview_StreetViewAnalysis_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fnstreetview_StreetViewAnalysis_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnstreetview_StreetViewAnalysis_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fnstreetview_StreetViewAnalysis_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnstreetview_StreetViewAnalysis_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fnstreetview_StreetViewAnalysis_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnstreetview_StreetViewAnalysis_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_SYS_Users" */
  rtcs_gm_dev_PH_SYS_Users: Array<Rtcs_Gm_Dev_Ph_Sys_Users>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_SYS_Users" */
  rtcs_gm_dev_PH_SYS_Users_aggregate: Rtcs_Gm_Dev_Ph_Sys_Users_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_rtcs_Country" */
  rtcs_gm_dev_PH_rtcs_Country: Array<Rtcs_Gm_Dev_Ph_Rtcs_Country>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_rtcs_Country" */
  rtcs_gm_dev_PH_rtcs_Country_aggregate: Rtcs_Gm_Dev_Ph_Rtcs_Country_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
  rtcs_gm_dev_PH_streetview_StreetViewAnalysis: Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
  rtcs_gm_dev_PH_streetview_StreetViewAnalysis_aggregate: Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.SYS_Users" */
  rtcs_gm_dev_SYS_Users: Array<Rtcs_Gm_Dev_Sys_Users>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.SYS_Users" */
  rtcs_gm_dev_SYS_Users_aggregate: Rtcs_Gm_Dev_Sys_Users_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_aggregate_result: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_aggregate_result_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_aggregate_result_txt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_aggregate_result_txt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.rtcs_Country" */
  rtcs_gm_dev_rtcs_Country: Array<Rtcs_Gm_Dev_Rtcs_Country>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.rtcs_Country" */
  rtcs_gm_dev_rtcs_Country_aggregate: Rtcs_Gm_Dev_Rtcs_Country_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.streetview_StreetViewAnalysis" */
  rtcs_gm_dev_streetview_StreetViewAnalysis: Array<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.streetview_StreetViewAnalysis" */
  rtcs_gm_dev_streetview_StreetViewAnalysis_aggregate: Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Aggregate;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_FnSys_UsersArgs = {
  args: Rtcs_Gm_Dev_FnSys_Users_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Sys_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Sys_Users_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Sys_Users_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_FnSys_Users_AggregateArgs = {
  args: Rtcs_Gm_Dev_FnSys_Users_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Sys_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Sys_Users_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Sys_Users_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_FnSys_Users_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_FnSys_Users_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_FnSys_Users_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_FnSys_Users_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_FnSys_Users_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_FnSys_Users_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_FnSys_Users_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_FnSys_Users_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnrtcs_CountryArgs = {
  args: Rtcs_Gm_Dev_Fnrtcs_Country_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Rtcs_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Rtcs_Country_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnrtcs_Country_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnrtcs_Country_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Rtcs_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Rtcs_Country_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnrtcs_Country_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fnrtcs_Country_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnrtcs_Country_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnrtcs_Country_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnrtcs_Country_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fnrtcs_Country_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnrtcs_Country_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnrtcs_Country_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnstreetview_StreetViewAnalysisArgs = {
  args: Rtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Sys_UsersArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Sys_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Sys_Users_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Sys_Users_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Sys_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Sys_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Sys_Users_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Sys_Users_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Rtcs_CountryArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Rtcs_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Rtcs_Country_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Rtcs_Country_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Rtcs_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Rtcs_Country_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysisArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Sys_UsersArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Sys_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Sys_Users_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Sys_Users_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Sys_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Sys_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Sys_Users_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Sys_Users_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Aggregate_ResultArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Aggregate_Result_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Aggregate_Result_TxtArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Aggregate_Result_Txt_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Rtcs_CountryArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Rtcs_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Rtcs_Country_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Rtcs_Country_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Rtcs_Country_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Rtcs_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Rtcs_Country_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Rtcs_Country_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Streetview_StreetViewAnalysisArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Bool_Exp>;
};


/** query root */
export type Query_RootRtcs_Gm_Dev_Streetview_StreetViewAnalysis_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Bool_Exp>;
};

export type Rtcs_Gm_Dev_FnSys_Users_Aggregatecm_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_FnSys_Users_Aggregatetxt_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_FnSys_Users_Args = {
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnrtcs_Country_Aggregatecm_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnrtcs_Country_Aggregatetxt_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnrtcs_Country_Args = {
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_Aggregatecm_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_Aggregatetxt_Args = {
  aggregate_params?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['String']['input']>;
};

export type Rtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_Args = {
  params?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "rtcs_gm_dev.PH_SYS_Users" */
export type Rtcs_Gm_Dev_Ph_Sys_Users = {
  __typename?: 'rtcs_gm_dev_PH_SYS_Users';
  Active?: Maybe<Scalars['Boolean']['output']>;
  CreationDate?: Maybe<Scalars['timestamptz']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  EmailVerified?: Maybe<Scalars['Boolean']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  GroupName?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  LoginAttemptCounter?: Maybe<Scalars['float8']['output']>;
  MobilePhone?: Maybe<Scalars['String']['output']>;
  Password?: Maybe<Scalars['String']['output']>;
  PasswordModificationDate?: Maybe<Scalars['timestamptz']['output']>;
  ProfilePicture?: Maybe<Scalars['String']['output']>;
  Updated?: Maybe<Scalars['timestamptz']['output']>;
  VisibleLevel?: Maybe<Scalars['String']['output']>;
  WorkAddress?: Maybe<Scalars['String']['output']>;
  WorkPhone?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.PH_SYS_Users" */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Aggregate = {
  __typename?: 'rtcs_gm_dev_PH_SYS_Users_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Ph_Sys_Users_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Ph_Sys_Users>;
};

/** aggregate fields of "rtcs_gm_dev.PH_SYS_Users" */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_PH_SYS_Users_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Ph_Sys_Users_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Ph_Sys_Users_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Ph_Sys_Users_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Ph_Sys_Users_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Ph_Sys_Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Ph_Sys_Users_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Ph_Sys_Users_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Ph_Sys_Users_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Ph_Sys_Users_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Ph_Sys_Users_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.PH_SYS_Users" */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Sys_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.PH_SYS_Users" */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Ph_Sys_Users_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Ph_Sys_Users_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Ph_Sys_Users_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Ph_Sys_Users_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Sys_Users_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Sys_Users_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Ph_Sys_Users_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Sys_Users_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Sys_Users_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Ph_Sys_Users_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.PH_SYS_Users" */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Ph_Sys_Users_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_PH_SYS_Users_avg_fields';
  LoginAttemptCounter?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.PH_SYS_Users" */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Avg_Order_By = {
  LoginAttemptCounter?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.PH_SYS_Users". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Bool_Exp = {
  Active?: InputMaybe<Boolean_Comparison_Exp>;
  CreationDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  Email?: InputMaybe<String_Comparison_Exp>;
  EmailVerified?: InputMaybe<Boolean_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  FirstName?: InputMaybe<String_Comparison_Exp>;
  GroupName?: InputMaybe<String_Comparison_Exp>;
  LastName?: InputMaybe<String_Comparison_Exp>;
  LoginAttemptCounter?: InputMaybe<Float8_Comparison_Exp>;
  MobilePhone?: InputMaybe<String_Comparison_Exp>;
  Password?: InputMaybe<String_Comparison_Exp>;
  PasswordModificationDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  ProfilePicture?: InputMaybe<String_Comparison_Exp>;
  Updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  VisibleLevel?: InputMaybe<String_Comparison_Exp>;
  WorkAddress?: InputMaybe<String_Comparison_Exp>;
  WorkPhone?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Sys_Users_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Ph_Sys_Users_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Sys_Users_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.PH_SYS_Users" */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Inc_Input = {
  LoginAttemptCounter?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.PH_SYS_Users" */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Insert_Input = {
  Active?: InputMaybe<Scalars['Boolean']['input']>;
  CreationDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  EmailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  FirstName?: InputMaybe<Scalars['String']['input']>;
  GroupName?: InputMaybe<Scalars['String']['input']>;
  LastName?: InputMaybe<Scalars['String']['input']>;
  LoginAttemptCounter?: InputMaybe<Scalars['float8']['input']>;
  MobilePhone?: InputMaybe<Scalars['String']['input']>;
  Password?: InputMaybe<Scalars['String']['input']>;
  PasswordModificationDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ProfilePicture?: InputMaybe<Scalars['String']['input']>;
  Updated?: InputMaybe<Scalars['timestamptz']['input']>;
  VisibleLevel?: InputMaybe<Scalars['String']['input']>;
  WorkAddress?: InputMaybe<Scalars['String']['input']>;
  WorkPhone?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Max_Fields = {
  __typename?: 'rtcs_gm_dev_PH_SYS_Users_max_fields';
  CreationDate?: Maybe<Scalars['timestamptz']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  GroupName?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  LoginAttemptCounter?: Maybe<Scalars['float8']['output']>;
  MobilePhone?: Maybe<Scalars['String']['output']>;
  Password?: Maybe<Scalars['String']['output']>;
  PasswordModificationDate?: Maybe<Scalars['timestamptz']['output']>;
  ProfilePicture?: Maybe<Scalars['String']['output']>;
  Updated?: Maybe<Scalars['timestamptz']['output']>;
  VisibleLevel?: Maybe<Scalars['String']['output']>;
  WorkAddress?: Maybe<Scalars['String']['output']>;
  WorkPhone?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.PH_SYS_Users" */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Max_Order_By = {
  CreationDate?: InputMaybe<Order_By>;
  Email?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  FirstName?: InputMaybe<Order_By>;
  GroupName?: InputMaybe<Order_By>;
  LastName?: InputMaybe<Order_By>;
  LoginAttemptCounter?: InputMaybe<Order_By>;
  MobilePhone?: InputMaybe<Order_By>;
  Password?: InputMaybe<Order_By>;
  PasswordModificationDate?: InputMaybe<Order_By>;
  ProfilePicture?: InputMaybe<Order_By>;
  Updated?: InputMaybe<Order_By>;
  VisibleLevel?: InputMaybe<Order_By>;
  WorkAddress?: InputMaybe<Order_By>;
  WorkPhone?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Min_Fields = {
  __typename?: 'rtcs_gm_dev_PH_SYS_Users_min_fields';
  CreationDate?: Maybe<Scalars['timestamptz']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  GroupName?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  LoginAttemptCounter?: Maybe<Scalars['float8']['output']>;
  MobilePhone?: Maybe<Scalars['String']['output']>;
  Password?: Maybe<Scalars['String']['output']>;
  PasswordModificationDate?: Maybe<Scalars['timestamptz']['output']>;
  ProfilePicture?: Maybe<Scalars['String']['output']>;
  Updated?: Maybe<Scalars['timestamptz']['output']>;
  VisibleLevel?: Maybe<Scalars['String']['output']>;
  WorkAddress?: Maybe<Scalars['String']['output']>;
  WorkPhone?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.PH_SYS_Users" */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Min_Order_By = {
  CreationDate?: InputMaybe<Order_By>;
  Email?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  FirstName?: InputMaybe<Order_By>;
  GroupName?: InputMaybe<Order_By>;
  LastName?: InputMaybe<Order_By>;
  LoginAttemptCounter?: InputMaybe<Order_By>;
  MobilePhone?: InputMaybe<Order_By>;
  Password?: InputMaybe<Order_By>;
  PasswordModificationDate?: InputMaybe<Order_By>;
  ProfilePicture?: InputMaybe<Order_By>;
  Updated?: InputMaybe<Order_By>;
  VisibleLevel?: InputMaybe<Order_By>;
  WorkAddress?: InputMaybe<Order_By>;
  WorkPhone?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.PH_SYS_Users" */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_PH_SYS_Users_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Ph_Sys_Users>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.PH_SYS_Users" */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Ph_Sys_Users_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.PH_SYS_Users" */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Order_By = {
  Active?: InputMaybe<Order_By>;
  CreationDate?: InputMaybe<Order_By>;
  Email?: InputMaybe<Order_By>;
  EmailVerified?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  FirstName?: InputMaybe<Order_By>;
  GroupName?: InputMaybe<Order_By>;
  LastName?: InputMaybe<Order_By>;
  LoginAttemptCounter?: InputMaybe<Order_By>;
  MobilePhone?: InputMaybe<Order_By>;
  Password?: InputMaybe<Order_By>;
  PasswordModificationDate?: InputMaybe<Order_By>;
  ProfilePicture?: InputMaybe<Order_By>;
  Updated?: InputMaybe<Order_By>;
  VisibleLevel?: InputMaybe<Order_By>;
  WorkAddress?: InputMaybe<Order_By>;
  WorkPhone?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.PH_SYS_Users" */
export enum Rtcs_Gm_Dev_Ph_Sys_Users_Select_Column {
  /** column name */
  Active = 'Active',
  /** column name */
  CreationDate = 'CreationDate',
  /** column name */
  Email = 'Email',
  /** column name */
  EmailVerified = 'EmailVerified',
  /** column name */
  File = 'File',
  /** column name */
  FirstName = 'FirstName',
  /** column name */
  GroupName = 'GroupName',
  /** column name */
  LastName = 'LastName',
  /** column name */
  LoginAttemptCounter = 'LoginAttemptCounter',
  /** column name */
  MobilePhone = 'MobilePhone',
  /** column name */
  Password = 'Password',
  /** column name */
  PasswordModificationDate = 'PasswordModificationDate',
  /** column name */
  ProfilePicture = 'ProfilePicture',
  /** column name */
  Updated = 'Updated',
  /** column name */
  VisibleLevel = 'VisibleLevel',
  /** column name */
  WorkAddress = 'WorkAddress',
  /** column name */
  WorkPhone = 'WorkPhone'
}

/** input type for updating data in table "rtcs_gm_dev.PH_SYS_Users" */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Set_Input = {
  Active?: InputMaybe<Scalars['Boolean']['input']>;
  CreationDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  EmailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  FirstName?: InputMaybe<Scalars['String']['input']>;
  GroupName?: InputMaybe<Scalars['String']['input']>;
  LastName?: InputMaybe<Scalars['String']['input']>;
  LoginAttemptCounter?: InputMaybe<Scalars['float8']['input']>;
  MobilePhone?: InputMaybe<Scalars['String']['input']>;
  Password?: InputMaybe<Scalars['String']['input']>;
  PasswordModificationDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ProfilePicture?: InputMaybe<Scalars['String']['input']>;
  Updated?: InputMaybe<Scalars['timestamptz']['input']>;
  VisibleLevel?: InputMaybe<Scalars['String']['input']>;
  WorkAddress?: InputMaybe<Scalars['String']['input']>;
  WorkPhone?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_PH_SYS_Users_stddev_fields';
  LoginAttemptCounter?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.PH_SYS_Users" */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Stddev_Order_By = {
  LoginAttemptCounter?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_SYS_Users_stddev_pop_fields';
  LoginAttemptCounter?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.PH_SYS_Users" */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Stddev_Pop_Order_By = {
  LoginAttemptCounter?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_SYS_Users_stddev_samp_fields';
  LoginAttemptCounter?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.PH_SYS_Users" */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Stddev_Samp_Order_By = {
  LoginAttemptCounter?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_PH_SYS_Users_sum_fields';
  LoginAttemptCounter?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.PH_SYS_Users" */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Sum_Order_By = {
  LoginAttemptCounter?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_SYS_Users_var_pop_fields';
  LoginAttemptCounter?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.PH_SYS_Users" */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Var_Pop_Order_By = {
  LoginAttemptCounter?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_SYS_Users_var_samp_fields';
  LoginAttemptCounter?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.PH_SYS_Users" */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Var_Samp_Order_By = {
  LoginAttemptCounter?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_PH_SYS_Users_variance_fields';
  LoginAttemptCounter?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.PH_SYS_Users" */
export type Rtcs_Gm_Dev_Ph_Sys_Users_Variance_Order_By = {
  LoginAttemptCounter?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.PH_rtcs_Country" */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country = {
  __typename?: 'rtcs_gm_dev_PH_rtcs_Country';
  Countrycode?: Maybe<Scalars['String']['output']>;
  Countryname?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.PH_rtcs_Country" */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Aggregate = {
  __typename?: 'rtcs_gm_dev_PH_rtcs_Country_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Ph_Rtcs_Country>;
};

/** aggregate fields of "rtcs_gm_dev.PH_rtcs_Country" */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_PH_rtcs_Country_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.PH_rtcs_Country" */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Rtcs_Country_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.PH_rtcs_Country" */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.PH_rtcs_Country" */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Ph_Rtcs_Country_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_PH_rtcs_Country_avg_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.PH_rtcs_Country" */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Avg_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.PH_rtcs_Country". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Bool_Exp = {
  Countrycode?: InputMaybe<String_Comparison_Exp>;
  Countryname?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Float8_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.PH_rtcs_Country" */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Inc_Input = {
  Line?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.PH_rtcs_Country" */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Insert_Input = {
  Countrycode?: InputMaybe<Scalars['String']['input']>;
  Countryname?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['float8']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Max_Fields = {
  __typename?: 'rtcs_gm_dev_PH_rtcs_Country_max_fields';
  Countrycode?: Maybe<Scalars['String']['output']>;
  Countryname?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.PH_rtcs_Country" */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Max_Order_By = {
  Countrycode?: InputMaybe<Order_By>;
  Countryname?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Min_Fields = {
  __typename?: 'rtcs_gm_dev_PH_rtcs_Country_min_fields';
  Countrycode?: Maybe<Scalars['String']['output']>;
  Countryname?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.PH_rtcs_Country" */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Min_Order_By = {
  Countrycode?: InputMaybe<Order_By>;
  Countryname?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.PH_rtcs_Country" */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_PH_rtcs_Country_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Ph_Rtcs_Country>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.PH_rtcs_Country" */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Ph_Rtcs_Country_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.PH_rtcs_Country" */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Order_By = {
  Countrycode?: InputMaybe<Order_By>;
  Countryname?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.PH_rtcs_Country" */
export enum Rtcs_Gm_Dev_Ph_Rtcs_Country_Select_Column {
  /** column name */
  Countrycode = 'Countrycode',
  /** column name */
  Countryname = 'Countryname',
  /** column name */
  File = 'File',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.PH_rtcs_Country" */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Set_Input = {
  Countrycode?: InputMaybe<Scalars['String']['input']>;
  Countryname?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['float8']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_PH_rtcs_Country_stddev_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.PH_rtcs_Country" */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Stddev_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_rtcs_Country_stddev_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.PH_rtcs_Country" */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Stddev_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_rtcs_Country_stddev_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.PH_rtcs_Country" */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Stddev_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_PH_rtcs_Country_sum_fields';
  Line?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.PH_rtcs_Country" */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Sum_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_rtcs_Country_var_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.PH_rtcs_Country" */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Var_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_rtcs_Country_var_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.PH_rtcs_Country" */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Var_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_PH_rtcs_Country_variance_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.PH_rtcs_Country" */
export type Rtcs_Gm_Dev_Ph_Rtcs_Country_Variance_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis = {
  __typename?: 'rtcs_gm_dev_PH_streetview_StreetViewAnalysis';
  Buildingaream2?: Maybe<Scalars['float8']['output']>;
  Buildingcentroidgps?: Maybe<Scalars['String']['output']>;
  Buildingtype?: Maybe<Scalars['String']['output']>;
  Businessname?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Latitude?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Longitude?: Maybe<Scalars['float8']['output']>;
  Numberofstoreys?: Maybe<Scalars['float8']['output']>;
  Phone1?: Maybe<Scalars['String']['output']>;
  Phone2?: Maybe<Scalars['float8']['output']>;
  Pluscode1?: Maybe<Scalars['String']['output']>;
  Pluscode2?: Maybe<Scalars['String']['output']>;
  Roofmaterial?: Maybe<Scalars['String']['output']>;
  Settlement?: Maybe<Scalars['String']['output']>;
  Street?: Maybe<Scalars['String']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  Wallmaterial?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Aggregate = {
  __typename?: 'rtcs_gm_dev_PH_streetview_StreetViewAnalysis_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis>;
};

/** aggregate fields of "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_PH_streetview_StreetViewAnalysis_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_PH_streetview_StreetViewAnalysis_avg_fields';
  Buildingaream2?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  Numberofstoreys?: Maybe<Scalars['Float']['output']>;
  Phone2?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Avg_Order_By = {
  Buildingaream2?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  Numberofstoreys?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.PH_streetview_StreetViewAnalysis". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Bool_Exp = {
  Buildingaream2?: InputMaybe<Float8_Comparison_Exp>;
  Buildingcentroidgps?: InputMaybe<String_Comparison_Exp>;
  Buildingtype?: InputMaybe<String_Comparison_Exp>;
  Businessname?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Latitude?: InputMaybe<Float8_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Longitude?: InputMaybe<Float8_Comparison_Exp>;
  Numberofstoreys?: InputMaybe<Float8_Comparison_Exp>;
  Phone1?: InputMaybe<String_Comparison_Exp>;
  Phone2?: InputMaybe<Float8_Comparison_Exp>;
  Pluscode1?: InputMaybe<String_Comparison_Exp>;
  Pluscode2?: InputMaybe<String_Comparison_Exp>;
  Roofmaterial?: InputMaybe<String_Comparison_Exp>;
  Settlement?: InputMaybe<String_Comparison_Exp>;
  Street?: InputMaybe<String_Comparison_Exp>;
  Uuid?: InputMaybe<String_Comparison_Exp>;
  Wallmaterial?: InputMaybe<String_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Inc_Input = {
  Buildingaream2?: InputMaybe<Scalars['float8']['input']>;
  Latitude?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Longitude?: InputMaybe<Scalars['float8']['input']>;
  Numberofstoreys?: InputMaybe<Scalars['float8']['input']>;
  Phone2?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Insert_Input = {
  Buildingaream2?: InputMaybe<Scalars['float8']['input']>;
  Buildingcentroidgps?: InputMaybe<Scalars['String']['input']>;
  Buildingtype?: InputMaybe<Scalars['String']['input']>;
  Businessname?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Latitude?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Longitude?: InputMaybe<Scalars['float8']['input']>;
  Numberofstoreys?: InputMaybe<Scalars['float8']['input']>;
  Phone1?: InputMaybe<Scalars['String']['input']>;
  Phone2?: InputMaybe<Scalars['float8']['input']>;
  Pluscode1?: InputMaybe<Scalars['String']['input']>;
  Pluscode2?: InputMaybe<Scalars['String']['input']>;
  Roofmaterial?: InputMaybe<Scalars['String']['input']>;
  Settlement?: InputMaybe<Scalars['String']['input']>;
  Street?: InputMaybe<Scalars['String']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  Wallmaterial?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Max_Fields = {
  __typename?: 'rtcs_gm_dev_PH_streetview_StreetViewAnalysis_max_fields';
  Buildingaream2?: Maybe<Scalars['float8']['output']>;
  Buildingcentroidgps?: Maybe<Scalars['String']['output']>;
  Buildingtype?: Maybe<Scalars['String']['output']>;
  Businessname?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Latitude?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Longitude?: Maybe<Scalars['float8']['output']>;
  Numberofstoreys?: Maybe<Scalars['float8']['output']>;
  Phone1?: Maybe<Scalars['String']['output']>;
  Phone2?: Maybe<Scalars['float8']['output']>;
  Pluscode1?: Maybe<Scalars['String']['output']>;
  Pluscode2?: Maybe<Scalars['String']['output']>;
  Roofmaterial?: Maybe<Scalars['String']['output']>;
  Settlement?: Maybe<Scalars['String']['output']>;
  Street?: Maybe<Scalars['String']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  Wallmaterial?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Max_Order_By = {
  Buildingaream2?: InputMaybe<Order_By>;
  Buildingcentroidgps?: InputMaybe<Order_By>;
  Buildingtype?: InputMaybe<Order_By>;
  Businessname?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  Numberofstoreys?: InputMaybe<Order_By>;
  Phone1?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
  Pluscode1?: InputMaybe<Order_By>;
  Pluscode2?: InputMaybe<Order_By>;
  Roofmaterial?: InputMaybe<Order_By>;
  Settlement?: InputMaybe<Order_By>;
  Street?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  Wallmaterial?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Min_Fields = {
  __typename?: 'rtcs_gm_dev_PH_streetview_StreetViewAnalysis_min_fields';
  Buildingaream2?: Maybe<Scalars['float8']['output']>;
  Buildingcentroidgps?: Maybe<Scalars['String']['output']>;
  Buildingtype?: Maybe<Scalars['String']['output']>;
  Businessname?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Latitude?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Longitude?: Maybe<Scalars['float8']['output']>;
  Numberofstoreys?: Maybe<Scalars['float8']['output']>;
  Phone1?: Maybe<Scalars['String']['output']>;
  Phone2?: Maybe<Scalars['float8']['output']>;
  Pluscode1?: Maybe<Scalars['String']['output']>;
  Pluscode2?: Maybe<Scalars['String']['output']>;
  Roofmaterial?: Maybe<Scalars['String']['output']>;
  Settlement?: Maybe<Scalars['String']['output']>;
  Street?: Maybe<Scalars['String']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  Wallmaterial?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Min_Order_By = {
  Buildingaream2?: InputMaybe<Order_By>;
  Buildingcentroidgps?: InputMaybe<Order_By>;
  Buildingtype?: InputMaybe<Order_By>;
  Businessname?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  Numberofstoreys?: InputMaybe<Order_By>;
  Phone1?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
  Pluscode1?: InputMaybe<Order_By>;
  Pluscode2?: InputMaybe<Order_By>;
  Roofmaterial?: InputMaybe<Order_By>;
  Settlement?: InputMaybe<Order_By>;
  Street?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  Wallmaterial?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_PH_streetview_StreetViewAnalysis_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Order_By = {
  Buildingaream2?: InputMaybe<Order_By>;
  Buildingcentroidgps?: InputMaybe<Order_By>;
  Buildingtype?: InputMaybe<Order_By>;
  Businessname?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  Numberofstoreys?: InputMaybe<Order_By>;
  Phone1?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
  Pluscode1?: InputMaybe<Order_By>;
  Pluscode2?: InputMaybe<Order_By>;
  Roofmaterial?: InputMaybe<Order_By>;
  Settlement?: InputMaybe<Order_By>;
  Street?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  Wallmaterial?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
export enum Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Select_Column {
  /** column name */
  Buildingaream2 = 'Buildingaream2',
  /** column name */
  Buildingcentroidgps = 'Buildingcentroidgps',
  /** column name */
  Buildingtype = 'Buildingtype',
  /** column name */
  Businessname = 'Businessname',
  /** column name */
  File = 'File',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Latitude = 'Latitude',
  /** column name */
  Line = 'Line',
  /** column name */
  Longitude = 'Longitude',
  /** column name */
  Numberofstoreys = 'Numberofstoreys',
  /** column name */
  Phone1 = 'Phone1',
  /** column name */
  Phone2 = 'Phone2',
  /** column name */
  Pluscode1 = 'Pluscode1',
  /** column name */
  Pluscode2 = 'Pluscode2',
  /** column name */
  Roofmaterial = 'Roofmaterial',
  /** column name */
  Settlement = 'Settlement',
  /** column name */
  Street = 'Street',
  /** column name */
  Uuid = 'Uuid',
  /** column name */
  Wallmaterial = 'Wallmaterial',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Set_Input = {
  Buildingaream2?: InputMaybe<Scalars['float8']['input']>;
  Buildingcentroidgps?: InputMaybe<Scalars['String']['input']>;
  Buildingtype?: InputMaybe<Scalars['String']['input']>;
  Businessname?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Latitude?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Longitude?: InputMaybe<Scalars['float8']['input']>;
  Numberofstoreys?: InputMaybe<Scalars['float8']['input']>;
  Phone1?: InputMaybe<Scalars['String']['input']>;
  Phone2?: InputMaybe<Scalars['float8']['input']>;
  Pluscode1?: InputMaybe<Scalars['String']['input']>;
  Pluscode2?: InputMaybe<Scalars['String']['input']>;
  Roofmaterial?: InputMaybe<Scalars['String']['input']>;
  Settlement?: InputMaybe<Scalars['String']['input']>;
  Street?: InputMaybe<Scalars['String']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  Wallmaterial?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_PH_streetview_StreetViewAnalysis_stddev_fields';
  Buildingaream2?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  Numberofstoreys?: Maybe<Scalars['Float']['output']>;
  Phone2?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Stddev_Order_By = {
  Buildingaream2?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  Numberofstoreys?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_streetview_StreetViewAnalysis_stddev_pop_fields';
  Buildingaream2?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  Numberofstoreys?: Maybe<Scalars['Float']['output']>;
  Phone2?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Stddev_Pop_Order_By = {
  Buildingaream2?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  Numberofstoreys?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_streetview_StreetViewAnalysis_stddev_samp_fields';
  Buildingaream2?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  Numberofstoreys?: Maybe<Scalars['Float']['output']>;
  Phone2?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Stddev_Samp_Order_By = {
  Buildingaream2?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  Numberofstoreys?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_PH_streetview_StreetViewAnalysis_sum_fields';
  Buildingaream2?: Maybe<Scalars['float8']['output']>;
  Latitude?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Longitude?: Maybe<Scalars['float8']['output']>;
  Numberofstoreys?: Maybe<Scalars['float8']['output']>;
  Phone2?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Sum_Order_By = {
  Buildingaream2?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  Numberofstoreys?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_PH_streetview_StreetViewAnalysis_var_pop_fields';
  Buildingaream2?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  Numberofstoreys?: Maybe<Scalars['Float']['output']>;
  Phone2?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Var_Pop_Order_By = {
  Buildingaream2?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  Numberofstoreys?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_PH_streetview_StreetViewAnalysis_var_samp_fields';
  Buildingaream2?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  Numberofstoreys?: Maybe<Scalars['Float']['output']>;
  Phone2?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Var_Samp_Order_By = {
  Buildingaream2?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  Numberofstoreys?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_PH_streetview_StreetViewAnalysis_variance_fields';
  Buildingaream2?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  Numberofstoreys?: Maybe<Scalars['Float']['output']>;
  Phone2?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Variance_Order_By = {
  Buildingaream2?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  Numberofstoreys?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.SYS_Users" */
export type Rtcs_Gm_Dev_Sys_Users = {
  __typename?: 'rtcs_gm_dev_SYS_Users';
  Active?: Maybe<Scalars['Boolean']['output']>;
  CreationDate?: Maybe<Scalars['timestamptz']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  EmailVerified?: Maybe<Scalars['Boolean']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  GroupName?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  LoginAttemptCounter?: Maybe<Scalars['float8']['output']>;
  MobilePhone?: Maybe<Scalars['String']['output']>;
  Password?: Maybe<Scalars['String']['output']>;
  PasswordModificationDate?: Maybe<Scalars['timestamptz']['output']>;
  ProfilePicture?: Maybe<Scalars['String']['output']>;
  Updated?: Maybe<Scalars['timestamptz']['output']>;
  VisibleLevel?: Maybe<Scalars['String']['output']>;
  WorkAddress?: Maybe<Scalars['String']['output']>;
  WorkPhone?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.SYS_Users" */
export type Rtcs_Gm_Dev_Sys_Users_Aggregate = {
  __typename?: 'rtcs_gm_dev_SYS_Users_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Sys_Users_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Sys_Users>;
};

/** aggregate fields of "rtcs_gm_dev.SYS_Users" */
export type Rtcs_Gm_Dev_Sys_Users_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_SYS_Users_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Sys_Users_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Sys_Users_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Sys_Users_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Sys_Users_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Sys_Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Sys_Users_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Sys_Users_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Sys_Users_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Sys_Users_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Sys_Users_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.SYS_Users" */
export type Rtcs_Gm_Dev_Sys_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Sys_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.SYS_Users" */
export type Rtcs_Gm_Dev_Sys_Users_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Sys_Users_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Sys_Users_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Sys_Users_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Sys_Users_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Sys_Users_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Sys_Users_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Sys_Users_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Sys_Users_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Sys_Users_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Sys_Users_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.SYS_Users" */
export type Rtcs_Gm_Dev_Sys_Users_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Sys_Users_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Sys_Users_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_SYS_Users_avg_fields';
  LoginAttemptCounter?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.SYS_Users" */
export type Rtcs_Gm_Dev_Sys_Users_Avg_Order_By = {
  LoginAttemptCounter?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.SYS_Users". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Sys_Users_Bool_Exp = {
  Active?: InputMaybe<Boolean_Comparison_Exp>;
  CreationDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  Email?: InputMaybe<String_Comparison_Exp>;
  EmailVerified?: InputMaybe<Boolean_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  FirstName?: InputMaybe<String_Comparison_Exp>;
  GroupName?: InputMaybe<String_Comparison_Exp>;
  LastName?: InputMaybe<String_Comparison_Exp>;
  LoginAttemptCounter?: InputMaybe<Float8_Comparison_Exp>;
  MobilePhone?: InputMaybe<String_Comparison_Exp>;
  Password?: InputMaybe<String_Comparison_Exp>;
  PasswordModificationDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  ProfilePicture?: InputMaybe<String_Comparison_Exp>;
  Updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  VisibleLevel?: InputMaybe<String_Comparison_Exp>;
  WorkAddress?: InputMaybe<String_Comparison_Exp>;
  WorkPhone?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Sys_Users_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Sys_Users_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Sys_Users_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.SYS_Users" */
export type Rtcs_Gm_Dev_Sys_Users_Inc_Input = {
  LoginAttemptCounter?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.SYS_Users" */
export type Rtcs_Gm_Dev_Sys_Users_Insert_Input = {
  Active?: InputMaybe<Scalars['Boolean']['input']>;
  CreationDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  EmailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  FirstName?: InputMaybe<Scalars['String']['input']>;
  GroupName?: InputMaybe<Scalars['String']['input']>;
  LastName?: InputMaybe<Scalars['String']['input']>;
  LoginAttemptCounter?: InputMaybe<Scalars['float8']['input']>;
  MobilePhone?: InputMaybe<Scalars['String']['input']>;
  Password?: InputMaybe<Scalars['String']['input']>;
  PasswordModificationDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ProfilePicture?: InputMaybe<Scalars['String']['input']>;
  Updated?: InputMaybe<Scalars['timestamptz']['input']>;
  VisibleLevel?: InputMaybe<Scalars['String']['input']>;
  WorkAddress?: InputMaybe<Scalars['String']['input']>;
  WorkPhone?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Sys_Users_Max_Fields = {
  __typename?: 'rtcs_gm_dev_SYS_Users_max_fields';
  CreationDate?: Maybe<Scalars['timestamptz']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  GroupName?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  LoginAttemptCounter?: Maybe<Scalars['float8']['output']>;
  MobilePhone?: Maybe<Scalars['String']['output']>;
  Password?: Maybe<Scalars['String']['output']>;
  PasswordModificationDate?: Maybe<Scalars['timestamptz']['output']>;
  ProfilePicture?: Maybe<Scalars['String']['output']>;
  Updated?: Maybe<Scalars['timestamptz']['output']>;
  VisibleLevel?: Maybe<Scalars['String']['output']>;
  WorkAddress?: Maybe<Scalars['String']['output']>;
  WorkPhone?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.SYS_Users" */
export type Rtcs_Gm_Dev_Sys_Users_Max_Order_By = {
  CreationDate?: InputMaybe<Order_By>;
  Email?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  FirstName?: InputMaybe<Order_By>;
  GroupName?: InputMaybe<Order_By>;
  LastName?: InputMaybe<Order_By>;
  LoginAttemptCounter?: InputMaybe<Order_By>;
  MobilePhone?: InputMaybe<Order_By>;
  Password?: InputMaybe<Order_By>;
  PasswordModificationDate?: InputMaybe<Order_By>;
  ProfilePicture?: InputMaybe<Order_By>;
  Updated?: InputMaybe<Order_By>;
  VisibleLevel?: InputMaybe<Order_By>;
  WorkAddress?: InputMaybe<Order_By>;
  WorkPhone?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Sys_Users_Min_Fields = {
  __typename?: 'rtcs_gm_dev_SYS_Users_min_fields';
  CreationDate?: Maybe<Scalars['timestamptz']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  GroupName?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  LoginAttemptCounter?: Maybe<Scalars['float8']['output']>;
  MobilePhone?: Maybe<Scalars['String']['output']>;
  Password?: Maybe<Scalars['String']['output']>;
  PasswordModificationDate?: Maybe<Scalars['timestamptz']['output']>;
  ProfilePicture?: Maybe<Scalars['String']['output']>;
  Updated?: Maybe<Scalars['timestamptz']['output']>;
  VisibleLevel?: Maybe<Scalars['String']['output']>;
  WorkAddress?: Maybe<Scalars['String']['output']>;
  WorkPhone?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.SYS_Users" */
export type Rtcs_Gm_Dev_Sys_Users_Min_Order_By = {
  CreationDate?: InputMaybe<Order_By>;
  Email?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  FirstName?: InputMaybe<Order_By>;
  GroupName?: InputMaybe<Order_By>;
  LastName?: InputMaybe<Order_By>;
  LoginAttemptCounter?: InputMaybe<Order_By>;
  MobilePhone?: InputMaybe<Order_By>;
  Password?: InputMaybe<Order_By>;
  PasswordModificationDate?: InputMaybe<Order_By>;
  ProfilePicture?: InputMaybe<Order_By>;
  Updated?: InputMaybe<Order_By>;
  VisibleLevel?: InputMaybe<Order_By>;
  WorkAddress?: InputMaybe<Order_By>;
  WorkPhone?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.SYS_Users" */
export type Rtcs_Gm_Dev_Sys_Users_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_SYS_Users_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Sys_Users>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.SYS_Users" */
export type Rtcs_Gm_Dev_Sys_Users_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Sys_Users_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.SYS_Users" */
export type Rtcs_Gm_Dev_Sys_Users_Order_By = {
  Active?: InputMaybe<Order_By>;
  CreationDate?: InputMaybe<Order_By>;
  Email?: InputMaybe<Order_By>;
  EmailVerified?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  FirstName?: InputMaybe<Order_By>;
  GroupName?: InputMaybe<Order_By>;
  LastName?: InputMaybe<Order_By>;
  LoginAttemptCounter?: InputMaybe<Order_By>;
  MobilePhone?: InputMaybe<Order_By>;
  Password?: InputMaybe<Order_By>;
  PasswordModificationDate?: InputMaybe<Order_By>;
  ProfilePicture?: InputMaybe<Order_By>;
  Updated?: InputMaybe<Order_By>;
  VisibleLevel?: InputMaybe<Order_By>;
  WorkAddress?: InputMaybe<Order_By>;
  WorkPhone?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.SYS_Users" */
export enum Rtcs_Gm_Dev_Sys_Users_Select_Column {
  /** column name */
  Active = 'Active',
  /** column name */
  CreationDate = 'CreationDate',
  /** column name */
  Email = 'Email',
  /** column name */
  EmailVerified = 'EmailVerified',
  /** column name */
  File = 'File',
  /** column name */
  FirstName = 'FirstName',
  /** column name */
  GroupName = 'GroupName',
  /** column name */
  LastName = 'LastName',
  /** column name */
  LoginAttemptCounter = 'LoginAttemptCounter',
  /** column name */
  MobilePhone = 'MobilePhone',
  /** column name */
  Password = 'Password',
  /** column name */
  PasswordModificationDate = 'PasswordModificationDate',
  /** column name */
  ProfilePicture = 'ProfilePicture',
  /** column name */
  Updated = 'Updated',
  /** column name */
  VisibleLevel = 'VisibleLevel',
  /** column name */
  WorkAddress = 'WorkAddress',
  /** column name */
  WorkPhone = 'WorkPhone'
}

/** input type for updating data in table "rtcs_gm_dev.SYS_Users" */
export type Rtcs_Gm_Dev_Sys_Users_Set_Input = {
  Active?: InputMaybe<Scalars['Boolean']['input']>;
  CreationDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  EmailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  FirstName?: InputMaybe<Scalars['String']['input']>;
  GroupName?: InputMaybe<Scalars['String']['input']>;
  LastName?: InputMaybe<Scalars['String']['input']>;
  LoginAttemptCounter?: InputMaybe<Scalars['float8']['input']>;
  MobilePhone?: InputMaybe<Scalars['String']['input']>;
  Password?: InputMaybe<Scalars['String']['input']>;
  PasswordModificationDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ProfilePicture?: InputMaybe<Scalars['String']['input']>;
  Updated?: InputMaybe<Scalars['timestamptz']['input']>;
  VisibleLevel?: InputMaybe<Scalars['String']['input']>;
  WorkAddress?: InputMaybe<Scalars['String']['input']>;
  WorkPhone?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Sys_Users_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_SYS_Users_stddev_fields';
  LoginAttemptCounter?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.SYS_Users" */
export type Rtcs_Gm_Dev_Sys_Users_Stddev_Order_By = {
  LoginAttemptCounter?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Sys_Users_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_SYS_Users_stddev_pop_fields';
  LoginAttemptCounter?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.SYS_Users" */
export type Rtcs_Gm_Dev_Sys_Users_Stddev_Pop_Order_By = {
  LoginAttemptCounter?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Sys_Users_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_SYS_Users_stddev_samp_fields';
  LoginAttemptCounter?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.SYS_Users" */
export type Rtcs_Gm_Dev_Sys_Users_Stddev_Samp_Order_By = {
  LoginAttemptCounter?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Sys_Users_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_SYS_Users_sum_fields';
  LoginAttemptCounter?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.SYS_Users" */
export type Rtcs_Gm_Dev_Sys_Users_Sum_Order_By = {
  LoginAttemptCounter?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Sys_Users_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_SYS_Users_var_pop_fields';
  LoginAttemptCounter?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.SYS_Users" */
export type Rtcs_Gm_Dev_Sys_Users_Var_Pop_Order_By = {
  LoginAttemptCounter?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Sys_Users_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_SYS_Users_var_samp_fields';
  LoginAttemptCounter?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.SYS_Users" */
export type Rtcs_Gm_Dev_Sys_Users_Var_Samp_Order_By = {
  LoginAttemptCounter?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Sys_Users_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_SYS_Users_variance_fields';
  LoginAttemptCounter?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.SYS_Users" */
export type Rtcs_Gm_Dev_Sys_Users_Variance_Order_By = {
  LoginAttemptCounter?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.aggregate_result" */
export type Rtcs_Gm_Dev_Aggregate_Result = {
  __typename?: 'rtcs_gm_dev_aggregate_result';
  operation?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.aggregate_result" */
export type Rtcs_Gm_Dev_Aggregate_Result_Aggregate = {
  __typename?: 'rtcs_gm_dev_aggregate_result_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Aggregate_Result_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Aggregate_Result>;
};

/** aggregate fields of "rtcs_gm_dev.aggregate_result" */
export type Rtcs_Gm_Dev_Aggregate_Result_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_aggregate_result_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Aggregate_Result_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Aggregate_Result_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Aggregate_Result_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Aggregate_Result_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Aggregate_Result_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Aggregate_Result_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Aggregate_Result_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Aggregate_Result_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Aggregate_Result_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Aggregate_Result_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.aggregate_result" */
export type Rtcs_Gm_Dev_Aggregate_Result_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.aggregate_result" */
export type Rtcs_Gm_Dev_Aggregate_Result_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.aggregate_result" */
export type Rtcs_Gm_Dev_Aggregate_Result_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Aggregate_Result_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Aggregate_Result_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_aggregate_result_avg_fields';
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.aggregate_result" */
export type Rtcs_Gm_Dev_Aggregate_Result_Avg_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.aggregate_result". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>>>;
  operation?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<Bigint_Comparison_Exp>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.aggregate_result" */
export type Rtcs_Gm_Dev_Aggregate_Result_Inc_Input = {
  value?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.aggregate_result" */
export type Rtcs_Gm_Dev_Aggregate_Result_Insert_Input = {
  operation?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Aggregate_Result_Max_Fields = {
  __typename?: 'rtcs_gm_dev_aggregate_result_max_fields';
  operation?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.aggregate_result" */
export type Rtcs_Gm_Dev_Aggregate_Result_Max_Order_By = {
  operation?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Aggregate_Result_Min_Fields = {
  __typename?: 'rtcs_gm_dev_aggregate_result_min_fields';
  operation?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.aggregate_result" */
export type Rtcs_Gm_Dev_Aggregate_Result_Min_Order_By = {
  operation?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.aggregate_result" */
export type Rtcs_Gm_Dev_Aggregate_Result_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_aggregate_result_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Aggregate_Result>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.aggregate_result" */
export type Rtcs_Gm_Dev_Aggregate_Result_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Aggregate_Result_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.aggregate_result" */
export type Rtcs_Gm_Dev_Aggregate_Result_Order_By = {
  operation?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.aggregate_result" */
export enum Rtcs_Gm_Dev_Aggregate_Result_Select_Column {
  /** column name */
  Operation = 'operation',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "rtcs_gm_dev.aggregate_result" */
export type Rtcs_Gm_Dev_Aggregate_Result_Set_Input = {
  operation?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Aggregate_Result_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_aggregate_result_stddev_fields';
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.aggregate_result" */
export type Rtcs_Gm_Dev_Aggregate_Result_Stddev_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Aggregate_Result_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_aggregate_result_stddev_pop_fields';
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.aggregate_result" */
export type Rtcs_Gm_Dev_Aggregate_Result_Stddev_Pop_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Aggregate_Result_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_aggregate_result_stddev_samp_fields';
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.aggregate_result" */
export type Rtcs_Gm_Dev_Aggregate_Result_Stddev_Samp_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Aggregate_Result_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_aggregate_result_sum_fields';
  value?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.aggregate_result" */
export type Rtcs_Gm_Dev_Aggregate_Result_Sum_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.aggregate_result_txt" */
export type Rtcs_Gm_Dev_Aggregate_Result_Txt = {
  __typename?: 'rtcs_gm_dev_aggregate_result_txt';
  operation?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.aggregate_result_txt" */
export type Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate = {
  __typename?: 'rtcs_gm_dev_aggregate_result_txt_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
};

/** aggregate fields of "rtcs_gm_dev.aggregate_result_txt" */
export type Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_aggregate_result_txt_aggregate_fields';
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Min_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.aggregate_result_txt" */
export type Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.aggregate_result_txt" */
export type Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Min_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.aggregate_result_txt" */
export type Rtcs_Gm_Dev_Aggregate_Result_Txt_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Insert_Input>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.aggregate_result_txt". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>>>;
  operation?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "rtcs_gm_dev.aggregate_result_txt" */
export type Rtcs_Gm_Dev_Aggregate_Result_Txt_Insert_Input = {
  operation?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Aggregate_Result_Txt_Max_Fields = {
  __typename?: 'rtcs_gm_dev_aggregate_result_txt_max_fields';
  operation?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.aggregate_result_txt" */
export type Rtcs_Gm_Dev_Aggregate_Result_Txt_Max_Order_By = {
  operation?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Aggregate_Result_Txt_Min_Fields = {
  __typename?: 'rtcs_gm_dev_aggregate_result_txt_min_fields';
  operation?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.aggregate_result_txt" */
export type Rtcs_Gm_Dev_Aggregate_Result_Txt_Min_Order_By = {
  operation?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.aggregate_result_txt" */
export type Rtcs_Gm_Dev_Aggregate_Result_Txt_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_aggregate_result_txt_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.aggregate_result_txt" */
export type Rtcs_Gm_Dev_Aggregate_Result_Txt_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Aggregate_Result_Txt_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.aggregate_result_txt" */
export type Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By = {
  operation?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.aggregate_result_txt" */
export enum Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column {
  /** column name */
  Operation = 'operation',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "rtcs_gm_dev.aggregate_result_txt" */
export type Rtcs_Gm_Dev_Aggregate_Result_Txt_Set_Input = {
  operation?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Aggregate_Result_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_aggregate_result_var_pop_fields';
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.aggregate_result" */
export type Rtcs_Gm_Dev_Aggregate_Result_Var_Pop_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Aggregate_Result_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_aggregate_result_var_samp_fields';
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.aggregate_result" */
export type Rtcs_Gm_Dev_Aggregate_Result_Var_Samp_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Aggregate_Result_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_aggregate_result_variance_fields';
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.aggregate_result" */
export type Rtcs_Gm_Dev_Aggregate_Result_Variance_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.rtcs_Country" */
export type Rtcs_Gm_Dev_Rtcs_Country = {
  __typename?: 'rtcs_gm_dev_rtcs_Country';
  Countrycode?: Maybe<Scalars['String']['output']>;
  Countryname?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.rtcs_Country" */
export type Rtcs_Gm_Dev_Rtcs_Country_Aggregate = {
  __typename?: 'rtcs_gm_dev_rtcs_Country_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Rtcs_Country_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Rtcs_Country>;
};

/** aggregate fields of "rtcs_gm_dev.rtcs_Country" */
export type Rtcs_Gm_Dev_Rtcs_Country_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_rtcs_Country_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Rtcs_Country_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Rtcs_Country_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Rtcs_Country_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Rtcs_Country_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Rtcs_Country_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Rtcs_Country_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Rtcs_Country_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Rtcs_Country_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Rtcs_Country_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Rtcs_Country_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.rtcs_Country" */
export type Rtcs_Gm_Dev_Rtcs_Country_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Rtcs_Country_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.rtcs_Country" */
export type Rtcs_Gm_Dev_Rtcs_Country_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Rtcs_Country_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Rtcs_Country_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Rtcs_Country_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Rtcs_Country_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Rtcs_Country_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Rtcs_Country_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Rtcs_Country_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Rtcs_Country_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Rtcs_Country_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Rtcs_Country_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.rtcs_Country" */
export type Rtcs_Gm_Dev_Rtcs_Country_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Rtcs_Country_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Rtcs_Country_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_rtcs_Country_avg_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.rtcs_Country" */
export type Rtcs_Gm_Dev_Rtcs_Country_Avg_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.rtcs_Country". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Rtcs_Country_Bool_Exp = {
  Countrycode?: InputMaybe<String_Comparison_Exp>;
  Countryname?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Line?: InputMaybe<Float8_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Rtcs_Country_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Rtcs_Country_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Rtcs_Country_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.rtcs_Country" */
export type Rtcs_Gm_Dev_Rtcs_Country_Inc_Input = {
  Line?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.rtcs_Country" */
export type Rtcs_Gm_Dev_Rtcs_Country_Insert_Input = {
  Countrycode?: InputMaybe<Scalars['String']['input']>;
  Countryname?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['float8']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Rtcs_Country_Max_Fields = {
  __typename?: 'rtcs_gm_dev_rtcs_Country_max_fields';
  Countrycode?: Maybe<Scalars['String']['output']>;
  Countryname?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.rtcs_Country" */
export type Rtcs_Gm_Dev_Rtcs_Country_Max_Order_By = {
  Countrycode?: InputMaybe<Order_By>;
  Countryname?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Rtcs_Country_Min_Fields = {
  __typename?: 'rtcs_gm_dev_rtcs_Country_min_fields';
  Countrycode?: Maybe<Scalars['String']['output']>;
  Countryname?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Line?: Maybe<Scalars['float8']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.rtcs_Country" */
export type Rtcs_Gm_Dev_Rtcs_Country_Min_Order_By = {
  Countrycode?: InputMaybe<Order_By>;
  Countryname?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.rtcs_Country" */
export type Rtcs_Gm_Dev_Rtcs_Country_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_rtcs_Country_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Rtcs_Country>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.rtcs_Country" */
export type Rtcs_Gm_Dev_Rtcs_Country_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Rtcs_Country_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.rtcs_Country" */
export type Rtcs_Gm_Dev_Rtcs_Country_Order_By = {
  Countrycode?: InputMaybe<Order_By>;
  Countryname?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.rtcs_Country" */
export enum Rtcs_Gm_Dev_Rtcs_Country_Select_Column {
  /** column name */
  Countrycode = 'Countrycode',
  /** column name */
  Countryname = 'Countryname',
  /** column name */
  File = 'File',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Line = 'Line',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.rtcs_Country" */
export type Rtcs_Gm_Dev_Rtcs_Country_Set_Input = {
  Countrycode?: InputMaybe<Scalars['String']['input']>;
  Countryname?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Line?: InputMaybe<Scalars['float8']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Rtcs_Country_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_rtcs_Country_stddev_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.rtcs_Country" */
export type Rtcs_Gm_Dev_Rtcs_Country_Stddev_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Rtcs_Country_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_rtcs_Country_stddev_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.rtcs_Country" */
export type Rtcs_Gm_Dev_Rtcs_Country_Stddev_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Rtcs_Country_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_rtcs_Country_stddev_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.rtcs_Country" */
export type Rtcs_Gm_Dev_Rtcs_Country_Stddev_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Rtcs_Country_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_rtcs_Country_sum_fields';
  Line?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.rtcs_Country" */
export type Rtcs_Gm_Dev_Rtcs_Country_Sum_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Rtcs_Country_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_rtcs_Country_var_pop_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.rtcs_Country" */
export type Rtcs_Gm_Dev_Rtcs_Country_Var_Pop_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Rtcs_Country_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_rtcs_Country_var_samp_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.rtcs_Country" */
export type Rtcs_Gm_Dev_Rtcs_Country_Var_Samp_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Rtcs_Country_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_rtcs_Country_variance_fields';
  Line?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.rtcs_Country" */
export type Rtcs_Gm_Dev_Rtcs_Country_Variance_Order_By = {
  Line?: InputMaybe<Order_By>;
};

/** columns and relationships of "rtcs_gm_dev.streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis = {
  __typename?: 'rtcs_gm_dev_streetview_StreetViewAnalysis';
  Buildingaream2?: Maybe<Scalars['float8']['output']>;
  Buildingcentroidgps?: Maybe<Scalars['String']['output']>;
  Buildingtype?: Maybe<Scalars['String']['output']>;
  Businessname?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Latitude?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Longitude?: Maybe<Scalars['float8']['output']>;
  Numberofstoreys?: Maybe<Scalars['float8']['output']>;
  Phone1?: Maybe<Scalars['String']['output']>;
  Phone2?: Maybe<Scalars['float8']['output']>;
  Pluscode1?: Maybe<Scalars['String']['output']>;
  Pluscode2?: Maybe<Scalars['String']['output']>;
  Roofmaterial?: Maybe<Scalars['String']['output']>;
  Settlement?: Maybe<Scalars['String']['output']>;
  Street?: Maybe<Scalars['String']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  Wallmaterial?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "rtcs_gm_dev.streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Aggregate = {
  __typename?: 'rtcs_gm_dev_streetview_StreetViewAnalysis_aggregate';
  aggregate?: Maybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Aggregate_Fields>;
  nodes: Array<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis>;
};

/** aggregate fields of "rtcs_gm_dev.streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Aggregate_Fields = {
  __typename?: 'rtcs_gm_dev_streetview_StreetViewAnalysis_aggregate_fields';
  avg?: Maybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Max_Fields>;
  min?: Maybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Min_Fields>;
  stddev?: Maybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Variance_Fields>;
};


/** aggregate fields of "rtcs_gm_dev.streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rtcs_gm_dev.streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Aggregate_Order_By = {
  avg?: InputMaybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Max_Order_By>;
  min?: InputMaybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Min_Order_By>;
  stddev?: InputMaybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Sum_Order_By>;
  var_pop?: InputMaybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Var_Samp_Order_By>;
  variance?: InputMaybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_gm_dev.streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Avg_Fields = {
  __typename?: 'rtcs_gm_dev_streetview_StreetViewAnalysis_avg_fields';
  Buildingaream2?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  Numberofstoreys?: Maybe<Scalars['Float']['output']>;
  Phone2?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rtcs_gm_dev.streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Avg_Order_By = {
  Buildingaream2?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  Numberofstoreys?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_gm_dev.streetview_StreetViewAnalysis". All fields are combined with a logical 'AND'. */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Bool_Exp = {
  Buildingaream2?: InputMaybe<Float8_Comparison_Exp>;
  Buildingcentroidgps?: InputMaybe<String_Comparison_Exp>;
  Buildingtype?: InputMaybe<String_Comparison_Exp>;
  Businessname?: InputMaybe<String_Comparison_Exp>;
  File?: InputMaybe<String_Comparison_Exp>;
  LastUpdated?: InputMaybe<Timestamptz_Comparison_Exp>;
  Latitude?: InputMaybe<Float8_Comparison_Exp>;
  Line?: InputMaybe<Bigint_Comparison_Exp>;
  Longitude?: InputMaybe<Float8_Comparison_Exp>;
  Numberofstoreys?: InputMaybe<Float8_Comparison_Exp>;
  Phone1?: InputMaybe<String_Comparison_Exp>;
  Phone2?: InputMaybe<Float8_Comparison_Exp>;
  Pluscode1?: InputMaybe<String_Comparison_Exp>;
  Pluscode2?: InputMaybe<String_Comparison_Exp>;
  Roofmaterial?: InputMaybe<String_Comparison_Exp>;
  Settlement?: InputMaybe<String_Comparison_Exp>;
  Street?: InputMaybe<String_Comparison_Exp>;
  Uuid?: InputMaybe<String_Comparison_Exp>;
  Wallmaterial?: InputMaybe<String_Comparison_Exp>;
  WhoUpdated?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Bool_Exp>>>;
  _not?: InputMaybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_gm_dev.streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Inc_Input = {
  Buildingaream2?: InputMaybe<Scalars['float8']['input']>;
  Latitude?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Longitude?: InputMaybe<Scalars['float8']['input']>;
  Numberofstoreys?: InputMaybe<Scalars['float8']['input']>;
  Phone2?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "rtcs_gm_dev.streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Insert_Input = {
  Buildingaream2?: InputMaybe<Scalars['float8']['input']>;
  Buildingcentroidgps?: InputMaybe<Scalars['String']['input']>;
  Buildingtype?: InputMaybe<Scalars['String']['input']>;
  Businessname?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Latitude?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Longitude?: InputMaybe<Scalars['float8']['input']>;
  Numberofstoreys?: InputMaybe<Scalars['float8']['input']>;
  Phone1?: InputMaybe<Scalars['String']['input']>;
  Phone2?: InputMaybe<Scalars['float8']['input']>;
  Pluscode1?: InputMaybe<Scalars['String']['input']>;
  Pluscode2?: InputMaybe<Scalars['String']['input']>;
  Roofmaterial?: InputMaybe<Scalars['String']['input']>;
  Settlement?: InputMaybe<Scalars['String']['input']>;
  Street?: InputMaybe<Scalars['String']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  Wallmaterial?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Max_Fields = {
  __typename?: 'rtcs_gm_dev_streetview_StreetViewAnalysis_max_fields';
  Buildingaream2?: Maybe<Scalars['float8']['output']>;
  Buildingcentroidgps?: Maybe<Scalars['String']['output']>;
  Buildingtype?: Maybe<Scalars['String']['output']>;
  Businessname?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Latitude?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Longitude?: Maybe<Scalars['float8']['output']>;
  Numberofstoreys?: Maybe<Scalars['float8']['output']>;
  Phone1?: Maybe<Scalars['String']['output']>;
  Phone2?: Maybe<Scalars['float8']['output']>;
  Pluscode1?: Maybe<Scalars['String']['output']>;
  Pluscode2?: Maybe<Scalars['String']['output']>;
  Roofmaterial?: Maybe<Scalars['String']['output']>;
  Settlement?: Maybe<Scalars['String']['output']>;
  Street?: Maybe<Scalars['String']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  Wallmaterial?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rtcs_gm_dev.streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Max_Order_By = {
  Buildingaream2?: InputMaybe<Order_By>;
  Buildingcentroidgps?: InputMaybe<Order_By>;
  Buildingtype?: InputMaybe<Order_By>;
  Businessname?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  Numberofstoreys?: InputMaybe<Order_By>;
  Phone1?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
  Pluscode1?: InputMaybe<Order_By>;
  Pluscode2?: InputMaybe<Order_By>;
  Roofmaterial?: InputMaybe<Order_By>;
  Settlement?: InputMaybe<Order_By>;
  Street?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  Wallmaterial?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Min_Fields = {
  __typename?: 'rtcs_gm_dev_streetview_StreetViewAnalysis_min_fields';
  Buildingaream2?: Maybe<Scalars['float8']['output']>;
  Buildingcentroidgps?: Maybe<Scalars['String']['output']>;
  Buildingtype?: Maybe<Scalars['String']['output']>;
  Businessname?: Maybe<Scalars['String']['output']>;
  File?: Maybe<Scalars['String']['output']>;
  LastUpdated?: Maybe<Scalars['timestamptz']['output']>;
  Latitude?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Longitude?: Maybe<Scalars['float8']['output']>;
  Numberofstoreys?: Maybe<Scalars['float8']['output']>;
  Phone1?: Maybe<Scalars['String']['output']>;
  Phone2?: Maybe<Scalars['float8']['output']>;
  Pluscode1?: Maybe<Scalars['String']['output']>;
  Pluscode2?: Maybe<Scalars['String']['output']>;
  Roofmaterial?: Maybe<Scalars['String']['output']>;
  Settlement?: Maybe<Scalars['String']['output']>;
  Street?: Maybe<Scalars['String']['output']>;
  Uuid?: Maybe<Scalars['String']['output']>;
  Wallmaterial?: Maybe<Scalars['String']['output']>;
  WhoUpdated?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rtcs_gm_dev.streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Min_Order_By = {
  Buildingaream2?: InputMaybe<Order_By>;
  Buildingcentroidgps?: InputMaybe<Order_By>;
  Buildingtype?: InputMaybe<Order_By>;
  Businessname?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  Numberofstoreys?: InputMaybe<Order_By>;
  Phone1?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
  Pluscode1?: InputMaybe<Order_By>;
  Pluscode2?: InputMaybe<Order_By>;
  Roofmaterial?: InputMaybe<Order_By>;
  Settlement?: InputMaybe<Order_By>;
  Street?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  Wallmaterial?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rtcs_gm_dev.streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Mutation_Response = {
  __typename?: 'rtcs_gm_dev_streetview_StreetViewAnalysis_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis>;
};

/** input type for inserting object relation for remote table "rtcs_gm_dev.streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Obj_Rel_Insert_Input = {
  data: Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Insert_Input;
};

/** ordering options when selecting data from "rtcs_gm_dev.streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Order_By = {
  Buildingaream2?: InputMaybe<Order_By>;
  Buildingcentroidgps?: InputMaybe<Order_By>;
  Buildingtype?: InputMaybe<Order_By>;
  Businessname?: InputMaybe<Order_By>;
  File?: InputMaybe<Order_By>;
  LastUpdated?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  Numberofstoreys?: InputMaybe<Order_By>;
  Phone1?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
  Pluscode1?: InputMaybe<Order_By>;
  Pluscode2?: InputMaybe<Order_By>;
  Roofmaterial?: InputMaybe<Order_By>;
  Settlement?: InputMaybe<Order_By>;
  Street?: InputMaybe<Order_By>;
  Uuid?: InputMaybe<Order_By>;
  Wallmaterial?: InputMaybe<Order_By>;
  WhoUpdated?: InputMaybe<Order_By>;
};

/** select columns of table "rtcs_gm_dev.streetview_StreetViewAnalysis" */
export enum Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Select_Column {
  /** column name */
  Buildingaream2 = 'Buildingaream2',
  /** column name */
  Buildingcentroidgps = 'Buildingcentroidgps',
  /** column name */
  Buildingtype = 'Buildingtype',
  /** column name */
  Businessname = 'Businessname',
  /** column name */
  File = 'File',
  /** column name */
  LastUpdated = 'LastUpdated',
  /** column name */
  Latitude = 'Latitude',
  /** column name */
  Line = 'Line',
  /** column name */
  Longitude = 'Longitude',
  /** column name */
  Numberofstoreys = 'Numberofstoreys',
  /** column name */
  Phone1 = 'Phone1',
  /** column name */
  Phone2 = 'Phone2',
  /** column name */
  Pluscode1 = 'Pluscode1',
  /** column name */
  Pluscode2 = 'Pluscode2',
  /** column name */
  Roofmaterial = 'Roofmaterial',
  /** column name */
  Settlement = 'Settlement',
  /** column name */
  Street = 'Street',
  /** column name */
  Uuid = 'Uuid',
  /** column name */
  Wallmaterial = 'Wallmaterial',
  /** column name */
  WhoUpdated = 'WhoUpdated'
}

/** input type for updating data in table "rtcs_gm_dev.streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Set_Input = {
  Buildingaream2?: InputMaybe<Scalars['float8']['input']>;
  Buildingcentroidgps?: InputMaybe<Scalars['String']['input']>;
  Buildingtype?: InputMaybe<Scalars['String']['input']>;
  Businessname?: InputMaybe<Scalars['String']['input']>;
  File?: InputMaybe<Scalars['String']['input']>;
  LastUpdated?: InputMaybe<Scalars['timestamptz']['input']>;
  Latitude?: InputMaybe<Scalars['float8']['input']>;
  Line?: InputMaybe<Scalars['bigint']['input']>;
  Longitude?: InputMaybe<Scalars['float8']['input']>;
  Numberofstoreys?: InputMaybe<Scalars['float8']['input']>;
  Phone1?: InputMaybe<Scalars['String']['input']>;
  Phone2?: InputMaybe<Scalars['float8']['input']>;
  Pluscode1?: InputMaybe<Scalars['String']['input']>;
  Pluscode2?: InputMaybe<Scalars['String']['input']>;
  Roofmaterial?: InputMaybe<Scalars['String']['input']>;
  Settlement?: InputMaybe<Scalars['String']['input']>;
  Street?: InputMaybe<Scalars['String']['input']>;
  Uuid?: InputMaybe<Scalars['String']['input']>;
  Wallmaterial?: InputMaybe<Scalars['String']['input']>;
  WhoUpdated?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Stddev_Fields = {
  __typename?: 'rtcs_gm_dev_streetview_StreetViewAnalysis_stddev_fields';
  Buildingaream2?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  Numberofstoreys?: Maybe<Scalars['Float']['output']>;
  Phone2?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rtcs_gm_dev.streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Stddev_Order_By = {
  Buildingaream2?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  Numberofstoreys?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Stddev_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_streetview_StreetViewAnalysis_stddev_pop_fields';
  Buildingaream2?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  Numberofstoreys?: Maybe<Scalars['Float']['output']>;
  Phone2?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rtcs_gm_dev.streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Stddev_Pop_Order_By = {
  Buildingaream2?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  Numberofstoreys?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Stddev_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_streetview_StreetViewAnalysis_stddev_samp_fields';
  Buildingaream2?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  Numberofstoreys?: Maybe<Scalars['Float']['output']>;
  Phone2?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rtcs_gm_dev.streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Stddev_Samp_Order_By = {
  Buildingaream2?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  Numberofstoreys?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Sum_Fields = {
  __typename?: 'rtcs_gm_dev_streetview_StreetViewAnalysis_sum_fields';
  Buildingaream2?: Maybe<Scalars['float8']['output']>;
  Latitude?: Maybe<Scalars['float8']['output']>;
  Line?: Maybe<Scalars['bigint']['output']>;
  Longitude?: Maybe<Scalars['float8']['output']>;
  Numberofstoreys?: Maybe<Scalars['float8']['output']>;
  Phone2?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "rtcs_gm_dev.streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Sum_Order_By = {
  Buildingaream2?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  Numberofstoreys?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Var_Pop_Fields = {
  __typename?: 'rtcs_gm_dev_streetview_StreetViewAnalysis_var_pop_fields';
  Buildingaream2?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  Numberofstoreys?: Maybe<Scalars['Float']['output']>;
  Phone2?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rtcs_gm_dev.streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Var_Pop_Order_By = {
  Buildingaream2?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  Numberofstoreys?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Var_Samp_Fields = {
  __typename?: 'rtcs_gm_dev_streetview_StreetViewAnalysis_var_samp_fields';
  Buildingaream2?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  Numberofstoreys?: Maybe<Scalars['Float']['output']>;
  Phone2?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rtcs_gm_dev.streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Var_Samp_Order_By = {
  Buildingaream2?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  Numberofstoreys?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Variance_Fields = {
  __typename?: 'rtcs_gm_dev_streetview_StreetViewAnalysis_variance_fields';
  Buildingaream2?: Maybe<Scalars['Float']['output']>;
  Latitude?: Maybe<Scalars['Float']['output']>;
  Line?: Maybe<Scalars['Float']['output']>;
  Longitude?: Maybe<Scalars['Float']['output']>;
  Numberofstoreys?: Maybe<Scalars['Float']['output']>;
  Phone2?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rtcs_gm_dev.streetview_StreetViewAnalysis" */
export type Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Variance_Order_By = {
  Buildingaream2?: InputMaybe<Order_By>;
  Latitude?: InputMaybe<Order_By>;
  Line?: InputMaybe<Order_By>;
  Longitude?: InputMaybe<Order_By>;
  Numberofstoreys?: InputMaybe<Order_By>;
  Phone2?: InputMaybe<Order_By>;
};

/** subscription root */
export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** execute function "rtcs_gm_dev.FnSYS_Users" which returns "rtcs_gm_dev.PH_SYS_Users" */
  rtcs_gm_dev_FnSYS_Users: Array<Rtcs_Gm_Dev_Ph_Sys_Users>;
  /** execute function "rtcs_gm_dev.FnSYS_Users" and query aggregates on result of table type "rtcs_gm_dev.PH_SYS_Users" */
  rtcs_gm_dev_FnSYS_Users_aggregate: Rtcs_Gm_Dev_Ph_Sys_Users_Aggregate;
  /** execute function "rtcs_gm_dev.FnSYS_Users_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_FnSYS_Users_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.FnSYS_Users_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_FnSYS_Users_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.FnSYS_Users_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_FnSYS_Users_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.FnSYS_Users_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_FnSYS_Users_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fnrtcs_Country" which returns "rtcs_gm_dev.PH_rtcs_Country" */
  rtcs_gm_dev_Fnrtcs_Country: Array<Rtcs_Gm_Dev_Ph_Rtcs_Country>;
  /** execute function "rtcs_gm_dev.Fnrtcs_Country" and query aggregates on result of table type "rtcs_gm_dev.PH_rtcs_Country" */
  rtcs_gm_dev_Fnrtcs_Country_aggregate: Rtcs_Gm_Dev_Ph_Rtcs_Country_Aggregate;
  /** execute function "rtcs_gm_dev.Fnrtcs_Country_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnrtcs_Country_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fnrtcs_Country_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnrtcs_Country_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fnrtcs_Country_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnrtcs_Country_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fnrtcs_Country_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnrtcs_Country_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_gm_dev.Fnstreetview_StreetViewAnalysis" which returns "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
  rtcs_gm_dev_Fnstreetview_StreetViewAnalysis: Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis>;
  /** execute function "rtcs_gm_dev.Fnstreetview_StreetViewAnalysis" and query aggregates on result of table type "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
  rtcs_gm_dev_Fnstreetview_StreetViewAnalysis_aggregate: Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Aggregate;
  /** execute function "rtcs_gm_dev.Fnstreetview_StreetViewAnalysis_aggregatecm" which returns "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnstreetview_StreetViewAnalysis_aggregatecm: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** execute function "rtcs_gm_dev.Fnstreetview_StreetViewAnalysis_aggregatecm" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_Fnstreetview_StreetViewAnalysis_aggregatecm_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** execute function "rtcs_gm_dev.Fnstreetview_StreetViewAnalysis_aggregatetxt" which returns "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnstreetview_StreetViewAnalysis_aggregatetxt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** execute function "rtcs_gm_dev.Fnstreetview_StreetViewAnalysis_aggregatetxt" and query aggregates on result of table type "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_Fnstreetview_StreetViewAnalysis_aggregatetxt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_SYS_Users" */
  rtcs_gm_dev_PH_SYS_Users: Array<Rtcs_Gm_Dev_Ph_Sys_Users>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_SYS_Users" */
  rtcs_gm_dev_PH_SYS_Users_aggregate: Rtcs_Gm_Dev_Ph_Sys_Users_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_rtcs_Country" */
  rtcs_gm_dev_PH_rtcs_Country: Array<Rtcs_Gm_Dev_Ph_Rtcs_Country>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_rtcs_Country" */
  rtcs_gm_dev_PH_rtcs_Country_aggregate: Rtcs_Gm_Dev_Ph_Rtcs_Country_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
  rtcs_gm_dev_PH_streetview_StreetViewAnalysis: Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.PH_streetview_StreetViewAnalysis" */
  rtcs_gm_dev_PH_streetview_StreetViewAnalysis_aggregate: Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.SYS_Users" */
  rtcs_gm_dev_SYS_Users: Array<Rtcs_Gm_Dev_Sys_Users>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.SYS_Users" */
  rtcs_gm_dev_SYS_Users_aggregate: Rtcs_Gm_Dev_Sys_Users_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_aggregate_result: Array<Rtcs_Gm_Dev_Aggregate_Result>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.aggregate_result" */
  rtcs_gm_dev_aggregate_result_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_aggregate_result_txt: Array<Rtcs_Gm_Dev_Aggregate_Result_Txt>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.aggregate_result_txt" */
  rtcs_gm_dev_aggregate_result_txt_aggregate: Rtcs_Gm_Dev_Aggregate_Result_Txt_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.rtcs_Country" */
  rtcs_gm_dev_rtcs_Country: Array<Rtcs_Gm_Dev_Rtcs_Country>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.rtcs_Country" */
  rtcs_gm_dev_rtcs_Country_aggregate: Rtcs_Gm_Dev_Rtcs_Country_Aggregate;
  /** fetch data from the table: "rtcs_gm_dev.streetview_StreetViewAnalysis" */
  rtcs_gm_dev_streetview_StreetViewAnalysis: Array<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis>;
  /** fetch aggregated fields from the table: "rtcs_gm_dev.streetview_StreetViewAnalysis" */
  rtcs_gm_dev_streetview_StreetViewAnalysis_aggregate: Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Aggregate;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_FnSys_UsersArgs = {
  args: Rtcs_Gm_Dev_FnSys_Users_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Sys_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Sys_Users_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Sys_Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_FnSys_Users_AggregateArgs = {
  args: Rtcs_Gm_Dev_FnSys_Users_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Sys_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Sys_Users_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Sys_Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_FnSys_Users_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_FnSys_Users_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_FnSys_Users_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_FnSys_Users_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_FnSys_Users_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_FnSys_Users_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_FnSys_Users_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_FnSys_Users_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnrtcs_CountryArgs = {
  args: Rtcs_Gm_Dev_Fnrtcs_Country_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Rtcs_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Rtcs_Country_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnrtcs_Country_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnrtcs_Country_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Rtcs_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Rtcs_Country_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnrtcs_Country_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fnrtcs_Country_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnrtcs_Country_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnrtcs_Country_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnrtcs_Country_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fnrtcs_Country_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnrtcs_Country_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnrtcs_Country_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnstreetview_StreetViewAnalysisArgs = {
  args: Rtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_AggregatecmArgs = {
  args: Rtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_Aggregatecm_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_Aggregatecm_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_AggregatetxtArgs = {
  args: Rtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Gm_Dev_Fnstreetview_StreetViewAnalysis_Aggregatetxt_Args;
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Sys_UsersArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Sys_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Sys_Users_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Sys_Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Sys_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Sys_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Sys_Users_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Sys_Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Rtcs_CountryArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Rtcs_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Rtcs_Country_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Rtcs_Country_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Rtcs_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Rtcs_Country_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Rtcs_Country_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysisArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Ph_Streetview_StreetViewAnalysis_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Sys_UsersArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Sys_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Sys_Users_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Sys_Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Sys_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Sys_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Sys_Users_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Sys_Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Aggregate_ResultArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Aggregate_Result_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Aggregate_Result_TxtArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Aggregate_Result_Txt_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Aggregate_Result_Txt_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Aggregate_Result_Txt_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Rtcs_CountryArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Rtcs_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Rtcs_Country_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Rtcs_Country_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Rtcs_Country_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Rtcs_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Rtcs_Country_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Rtcs_Country_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Streetview_StreetViewAnalysisArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRtcs_Gm_Dev_Streetview_StreetViewAnalysis_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Order_By>>;
  where?: InputMaybe<Rtcs_Gm_Dev_Streetview_StreetViewAnalysis_Bool_Exp>;
};

/** expression to compare columns of type timestamptz. All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
};

export type StreetViewFragment = { __typename?: 'rtcs_gm_dev_PH_streetview_StreetViewAnalysis', Buildingaream2?: any | null, Buildingcentroidgps?: string | null, Buildingtype?: string | null, Businessname?: string | null, LastUpdated?: any | null, Latitude?: any | null, Longitude?: any | null, Numberofstoreys?: any | null, Phone1?: string | null, Phone2?: any | null, Pluscode1?: string | null, Pluscode2?: string | null, Roofmaterial?: string | null, Settlement?: string | null, Street?: string | null, Uuid?: string | null, Wallmaterial?: string | null, WhoUpdated?: string | null };

export type StreetViewQueryVariables = Exact<{
  params: Scalars['String']['input'];
}>;


export type StreetViewQuery = { __typename?: 'query_root', rtcs_gm_dev_Fnstreetview_StreetViewAnalysis: Array<{ __typename?: 'rtcs_gm_dev_PH_streetview_StreetViewAnalysis', Buildingaream2?: any | null, Buildingcentroidgps?: string | null, Buildingtype?: string | null, Businessname?: string | null, LastUpdated?: any | null, Latitude?: any | null, Longitude?: any | null, Numberofstoreys?: any | null, Phone1?: string | null, Phone2?: any | null, Pluscode1?: string | null, Pluscode2?: string | null, Roofmaterial?: string | null, Settlement?: string | null, Street?: string | null, Uuid?: string | null, Wallmaterial?: string | null, WhoUpdated?: string | null }> };

export type PaginatedStreetViewQueryVariables = Exact<{
  params: Scalars['String']['input'];
  aggregateParams: Scalars['String']['input'];
}>;


export type PaginatedStreetViewQuery = { __typename?: 'query_root', rtcs_gm_dev_Fnstreetview_StreetViewAnalysis: Array<{ __typename?: 'rtcs_gm_dev_PH_streetview_StreetViewAnalysis', Buildingaream2?: any | null, Buildingcentroidgps?: string | null, Buildingtype?: string | null, Businessname?: string | null, LastUpdated?: any | null, Latitude?: any | null, Longitude?: any | null, Numberofstoreys?: any | null, Phone1?: string | null, Phone2?: any | null, Pluscode1?: string | null, Pluscode2?: string | null, Roofmaterial?: string | null, Settlement?: string | null, Street?: string | null, Uuid?: string | null, Wallmaterial?: string | null, WhoUpdated?: string | null }>, rtcs_gm_dev_Fnstreetview_StreetViewAnalysis_aggregatecm: Array<{ __typename?: 'rtcs_gm_dev_aggregate_result', operation?: string | null, value?: any | null }> };

export type StreetViewFilterQueryVariables = Exact<{
  params: Scalars['String']['input'];
}>;


export type StreetViewFilterQuery = { __typename?: 'query_root', rtcs_gm_dev_Fnstreetview_StreetViewAnalysis: Array<{ __typename?: 'rtcs_gm_dev_PH_streetview_StreetViewAnalysis', Buildingaream2?: any | null, Buildingcentroidgps?: string | null, Buildingtype?: string | null, Businessname?: string | null, LastUpdated?: any | null, Latitude?: any | null, Longitude?: any | null, Numberofstoreys?: any | null, Phone1?: string | null, Phone2?: any | null, Pluscode1?: string | null, Pluscode2?: string | null, Roofmaterial?: string | null, Settlement?: string | null, Street?: string | null, Uuid?: string | null, Wallmaterial?: string | null, WhoUpdated?: string | null }> };

export const StreetViewFragmentDoc = gql`
    fragment StreetView on rtcs_gm_dev_PH_streetview_StreetViewAnalysis {
  Buildingaream2
  Buildingcentroidgps
  Buildingtype
  Businessname
  LastUpdated
  Latitude
  Longitude
  Numberofstoreys
  Phone1
  Phone2
  Pluscode1
  Pluscode2
  Roofmaterial
  Settlement
  Street
  Uuid
  Wallmaterial
  WhoUpdated
}
    `;
export const StreetViewDocument = gql`
    query StreetView($params: String!) {
  rtcs_gm_dev_Fnstreetview_StreetViewAnalysis(args: {params: $params}) {
    ...StreetView
  }
}
    ${StreetViewFragmentDoc}`;

/**
 * __useStreetViewQuery__
 *
 * To run a query within a React component, call `useStreetViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useStreetViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStreetViewQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useStreetViewQuery(baseOptions: Apollo.QueryHookOptions<StreetViewQuery, StreetViewQueryVariables> & ({ variables: StreetViewQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StreetViewQuery, StreetViewQueryVariables>(StreetViewDocument, options);
      }
export function useStreetViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StreetViewQuery, StreetViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StreetViewQuery, StreetViewQueryVariables>(StreetViewDocument, options);
        }
export function useStreetViewSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<StreetViewQuery, StreetViewQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<StreetViewQuery, StreetViewQueryVariables>(StreetViewDocument, options);
        }
export type StreetViewQueryHookResult = ReturnType<typeof useStreetViewQuery>;
export type StreetViewLazyQueryHookResult = ReturnType<typeof useStreetViewLazyQuery>;
export type StreetViewSuspenseQueryHookResult = ReturnType<typeof useStreetViewSuspenseQuery>;
export type StreetViewQueryResult = Apollo.QueryResult<StreetViewQuery, StreetViewQueryVariables>;
export const PaginatedStreetViewDocument = gql`
    query paginatedStreetView($params: String!, $aggregateParams: String!) {
  rtcs_gm_dev_Fnstreetview_StreetViewAnalysis(args: {params: $params}) {
    ...StreetView
  }
  rtcs_gm_dev_Fnstreetview_StreetViewAnalysis_aggregatecm(
    args: {aggregate_params: "{\\"count\\": \\"\\"}", params: $aggregateParams}
  ) {
    operation
    value
  }
}
    ${StreetViewFragmentDoc}`;

/**
 * __usePaginatedStreetViewQuery__
 *
 * To run a query within a React component, call `usePaginatedStreetViewQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedStreetViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedStreetViewQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function usePaginatedStreetViewQuery(baseOptions: Apollo.QueryHookOptions<PaginatedStreetViewQuery, PaginatedStreetViewQueryVariables> & ({ variables: PaginatedStreetViewQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaginatedStreetViewQuery, PaginatedStreetViewQueryVariables>(PaginatedStreetViewDocument, options);
      }
export function usePaginatedStreetViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaginatedStreetViewQuery, PaginatedStreetViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaginatedStreetViewQuery, PaginatedStreetViewQueryVariables>(PaginatedStreetViewDocument, options);
        }
export function usePaginatedStreetViewSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PaginatedStreetViewQuery, PaginatedStreetViewQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PaginatedStreetViewQuery, PaginatedStreetViewQueryVariables>(PaginatedStreetViewDocument, options);
        }
export type PaginatedStreetViewQueryHookResult = ReturnType<typeof usePaginatedStreetViewQuery>;
export type PaginatedStreetViewLazyQueryHookResult = ReturnType<typeof usePaginatedStreetViewLazyQuery>;
export type PaginatedStreetViewSuspenseQueryHookResult = ReturnType<typeof usePaginatedStreetViewSuspenseQuery>;
export type PaginatedStreetViewQueryResult = Apollo.QueryResult<PaginatedStreetViewQuery, PaginatedStreetViewQueryVariables>;
export const StreetViewFilterDocument = gql`
    query StreetViewFilter($params: String!) {
  rtcs_gm_dev_Fnstreetview_StreetViewAnalysis(args: {params: $params}) {
    ...StreetView
  }
}
    ${StreetViewFragmentDoc}`;

/**
 * __useStreetViewFilterQuery__
 *
 * To run a query within a React component, call `useStreetViewFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useStreetViewFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStreetViewFilterQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useStreetViewFilterQuery(baseOptions: Apollo.QueryHookOptions<StreetViewFilterQuery, StreetViewFilterQueryVariables> & ({ variables: StreetViewFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StreetViewFilterQuery, StreetViewFilterQueryVariables>(StreetViewFilterDocument, options);
      }
export function useStreetViewFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StreetViewFilterQuery, StreetViewFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StreetViewFilterQuery, StreetViewFilterQueryVariables>(StreetViewFilterDocument, options);
        }
export function useStreetViewFilterSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<StreetViewFilterQuery, StreetViewFilterQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<StreetViewFilterQuery, StreetViewFilterQueryVariables>(StreetViewFilterDocument, options);
        }
export type StreetViewFilterQueryHookResult = ReturnType<typeof useStreetViewFilterQuery>;
export type StreetViewFilterLazyQueryHookResult = ReturnType<typeof useStreetViewFilterLazyQuery>;
export type StreetViewFilterSuspenseQueryHookResult = ReturnType<typeof useStreetViewFilterSuspenseQuery>;
export type StreetViewFilterQueryResult = Apollo.QueryResult<StreetViewFilterQuery, StreetViewFilterQueryVariables>;