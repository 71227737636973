/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/static-property-placement */
/* eslint-disable import/no-unresolved */
// Dependencies
import React from "react";
import PropTypes from "prop-types";
import GoogleMapReact from "google-map-react";
import { useNavigate, useParams } from "react-router";
import SC from "./styles";
// Components
// import { fitBounds } from "google-map-react";
import Place from "./Place";
import PlaceDescription from "./PlaceDescription";

// Shapes
import {
  coordinateShape,
  layerShape,
  featureShape,
  projectCategoryFromCSSShape,
} from "../propTypesShapes";
import { gMapTypeIdType } from "../../settings/propTypesShapes";

// Utils
import { ZoomControl } from "../utils/map";
import { createPopupInstance } from "../utils/popup/popup";
import { isFirstRender } from "../../shared/utils/data";
// import { logToConsole } from '../../shared/utils/log';

// Assets
import "./Map.css";
import "../utils/popup/popup.css";

const MAX_ZOOM = 21;

const { REACT_APP_GOOGLE_MAPS_API_KEY } = process.env;

const createMapOptions = (mapTypeId) => {
  return {
    fullscreenControl: false,
    zoomControl: false,
    // Enabling zoom and pan interaction on desktop, touchscreen, and mobile devices
    // using the scroll wheel on a mouse, the zoom controls, a trackpad/mouse, or
    // a touchscreen.
    gestureHandling: "greedy",
    scaleControl: true,
    mapTypeId,
  };
};

class Map extends React.Component {
  static propTypes = {
    token: PropTypes.string,
    projectCategoryFromCSS: projectCategoryFromCSSShape,
    defaultCenter: coordinateShape.isRequired,
    defaultZoom: PropTypes.number,
    currentLayer: layerShape,
    mapTypeId: gMapTypeIdType,
    currentFeature: featureShape,
  };

  constructor(props) {
    super(props);

    this.infoWindow = null;
    this.state = {
      google: null,
      mapTypeId: "satellite",
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { currentLayer, currentFeature, mapTypeId } = props;
    if (
      currentLayer &&
      currentLayer.dataLayer &&
      !isFirstRender(currentLayer.dataLayer.features)
    ) {
      return {
        mapTypeId,
      };
    }
    return null;
  }

  componentDidMount(prevProps, nextProps) {
    const { google } = this.state;
  }

  shouldComponentUpdate(newProps, newState) {
    const { currentFeature, mapTypeId } = newProps;
    const { updateClusters } = this.state;

    if (mapTypeId === newState.mapTypeId) {
      return true;
    }

    if (currentFeature !== this.props.currentFeature) {
      return true;
    }
    if (updateClusters) {
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps, _prevState) {
    const { currentLayer, currentFeature, profile, navigate } = this.props;
    const { google } = this.state;
    const dataLayer = currentLayer?.dataLayer;

    if (prevProps.currentFeature !== currentFeature) {
      if (currentFeature) {
        const { map } = google;
        const { position } = currentFeature;

        map.setZoom(MAX_ZOOM);
        this.infoWindow.setContent(
          <PlaceDescription
            feature={currentFeature}
            navigate={navigate}
            profile={profile}
          />,
        );
        this.infoWindow.setPosition(position);
        map.setCenter(position);
        this.infoWindow.open(); // map);
      } else {
        this.infoWindow.close();
      }
    }
  }

  handleOnChange = (newViewportProperties) => {
    const { currentFeature } = this.props;

    // Zoom In Action when InfoWindow is opened
    if (
      currentFeature &&
      newViewportProperties &&
      this.infoWindow &&
      this.infoWindow.isOpen()
    ) {
      const { map } = this.state.google;
      map.panTo(currentFeature.position); // this.infoWindow.getPosition() );
      this.infoWindow.open(); // map);
    }
  };

  handleOnChildClick = (key, childProps) => {
    const { token, profile, currentLayer, currentFeature } = this.props;
    const featureIndex = parseInt(key, 10);

    if (featureIndex >= 0) {
      const feature = currentLayer.dataLayer.features[featureIndex];
      if (currentFeature && currentFeature.id === feature.id) {
        if (!this.infoWindow.isOpen()) {
          this.infoWindow.open(); // this.state.google.map);
        }
      }
    }
  };

  handleZoomInControlOnClick = () => {
    const { map } = this.state.google;
    map.setZoom(map.getZoom() + 1);
  };

  handleZoomOutControlOnClick = () => {
    const { map } = this.state.google;
    map.setZoom(map.getZoom() - 1);
  };

  handleOnGoogleApiLoaded = (google) => {
    const { map, maps } = google;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    maps.ControlPosition.LEFT_TOP;

    // Add method to verify if InfoWindow is opened
    // maps.InfoWindow.prototype.isOpen = function() {
    //   var map = this.getMap();
    //   return (map !== null && typeof map !== 'undefined');
    // };
    // this.infoWindow = new maps.InfoWindow();

    this.infoWindow = createPopupInstance(maps, map);

    const zoomControlDiv = ZoomControl(
      1,
      this.handleZoomInControlOnClick,
      this.handleZoomOutControlOnClick,
    );

    map.controls[maps.ControlPosition.RIGHT_BOTTOM].push(zoomControlDiv);
    const bounds = maps.LatLngBounds();
    this.setState({ google });
  };

  render() {
    const {
      defaultCenter,
      defaultZoom,
      currentLayer,
      projectCategoryFromCSS, // , location
    } = this.props;
    const { mapTypeId } = this.state;
    const dataLayer = currentLayer?.dataLayer;

    let center = {
      lat: defaultCenter.latitude,
      lng: defaultCenter.longitude,
    };

    if (dataLayer) {
      if (dataLayer.features.length > 0) {
        const property = dataLayer.features[0];
        const [longitude, latitude] = property.geometry.coordinates;
        center = {
          lat: latitude,
          lng: longitude,
        };
      }
    }

    /* const zoom =
      viewportProperties?.zoom ||
      defaultZoom ||
      calculateZoomByLatitudeAndDesiredMeters(center.lat, 200000); */
    const resetBoundsOnResize = true; // && location.state && location.state.fromBottomMenuBar;

    const defaultIcon = "/assets/icons/rtcs_point_icon.png";

    return (
      <SC.MapContainer id="Map">
        <GoogleMapReact
          bootstrapURLKeys={{ key: REACT_APP_GOOGLE_MAPS_API_KEY }}
          options={(maps) => createMapOptions(mapTypeId)}
          center={center}
          defaultZoom={defaultZoom}
          onChange={this.handleOnChange}
          onChildClick={this.handleOnChildClick}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={this.handleOnGoogleApiLoaded}
          resetBoundsOnResize={resetBoundsOnResize}
        >
          {dataLayer.features.map((property, index) => {
            const [longitude, latitude] = property.geometry.coordinates;
            return (
              <Place
                key={index}
                lat={latitude}
                lng={longitude}
                text=""
                defaultIcon={defaultIcon}
                onClick={() =>
                  this.props.setCurrentFeature({ ...property, scroll: true })
                }
              />
            );
          })}
        </GoogleMapReact>
      </SC.MapContainer>
    );
  }
}

Map.defaultProps = {
  defaultCenter: {
    latitude: 0.3132008,
    longitude: 32.5290858,
  },
  defaultZoom: MAX_ZOOM,
};

const ControlledMap = (props) => {
  const navigate = useNavigate();
  const params = useParams();

  return <Map {...props} navigate={navigate} params={params} />;
};

export default ControlledMap;
