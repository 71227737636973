// Dependencies
import React, { useCallback, useMemo } from "react";
import { TableCellProps } from "@mui/material";
import { useWindowHeight } from "@react-hook/window-size";
import { debounce } from "lodash";

import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  getExpandedRowModel,
  ColumnDef,
  Cell,
  Row,
  TableOptions,
  ColumnMeta,
  flexRender,
  Column,
} from "@tanstack/react-table";

import _ from "lodash";

// Components
import TableToolbar from "components/table-toolbar/table-toolbar.component";
import { TableToolbarAction } from "components/table-toolbar-actions/table-toolbar-actions.component";
import LeftPaneContainer from "components/left-pane/left-pane.component";
import TransferList from "components/transfer-list/transfer-list.component";
import FormattedValue from "components/formatted-value/formatted-value";
import { TableToolbarButtonProps } from "components/table-toolbar-button/table-toolbar-button.component";

// Assets
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import SC from "./table.styles";

const EXPANDER_CELL_ID = "expander";
export const VISIBILITY_CELL_ID = "visibility";

export const ROWS_PER_PAGE_OPTIONS_DEFAULT = [5, 10, 25];
export const INITIAL_ROWS_PER_PAGE = {
  DEFAULT: 25,
  REPORTS: 25,
  UNLIMITED: -1,
};

interface ExpanderCellProps {
  row: Row<any>;
}

const expanderCell: ColumnDef<any> = {
  header: () => null,
  id: EXPANDER_CELL_ID,
  enableSorting: true,
  cell: ({ row }: ExpanderCellProps) => {
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
      event.preventDefault();
      row.toggleExpanded();
    };

    return (
      <SC.ExpanderIconButton onClick={handleClick}>
        {row.getIsExpanded() ? (
          <KeyboardArrowDownIcon />
        ) : (
          <KeyboardArrowRightIcon />
        )}
      </SC.ExpanderIconButton>
    );
  },
  enableResizing: true,
  size: 55,
  minSize: 50,
  maxSize: 50,
};

const getSelectorCell = (
  header: string | undefined,
  onRowSelect?: (values: { Rnid: string; Visible: boolean }) => void,
): ColumnDef<any> => ({
  id: VISIBILITY_CELL_ID,
  header: () => header || null,
  enableSorting: true,
  cell: ({ row }: ExpanderCellProps) => (
    <SC.VisibilityIcon
      color="primary"
      onChange={(event: React.ChangeEvent, checked: boolean) => {
        row.toggleSelected(checked);
        const rnid = row.getValue("Rnid");
        if (_.isString(rnid)) {
          onRowSelect?.({ Rnid: rnid, Visible: checked });
        }
        event.stopPropagation();
      }}
      defaultChecked={row.original.Visible}
    />
  ),
  enableResizing: true,
  size: 80,
  minSize: 80,
  maxSize: 80,
});

export const TABLE_CELL_FORMATS = {
  DATE: "date",
  NUMBER: "number",
  CURRENCY: "currency",
  STRING: "string",
  PERCENT: "percent",
};

export const formatCellData = <D extends Record<string, unknown>>(
  format: string | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cell: Cell<D, any>,
): React.ReactNode => {
  const cellValue = cell.getValue();

  if (cellValue === null) return "";

  switch (format) {
    case TABLE_CELL_FORMATS.CURRENCY:
      if (typeof cellValue === "string" && parseInt(cellValue, 10) === 0)
        return 0;
      return cell.row.index === 0 ? (
        <FormattedValue format="currency" value={cellValue} />
      ) : (
        <FormattedValue value={cellValue} format="number" />
      );

    case TABLE_CELL_FORMATS.DATE:
      return <FormattedValue format="date" value={cellValue} />;

    case TABLE_CELL_FORMATS.NUMBER:
      return <FormattedValue value={cellValue} format="number" />;

    case TABLE_CELL_FORMATS.PERCENT:
      if (cellValue === 0) return 0;

      return cell.row.index === 0 ? (
        <FormattedValue value={cellValue / 100} format="percent" />
      ) : (
        (cellValue * 1).toFixed(1).replace(/\.0+$/, "")
      );

    case TABLE_CELL_FORMATS.STRING:
      return cellValue === "nan" ? "" : cellValue;

    default:
      return cellValue;
  }
};

interface CustomColumnMeta {
  sort?: boolean;
}

export type TableColumn<D extends Record<string, unknown>> = ColumnDef<D> & {
  accessorKey?: keyof D;
  align?: TableCellProps["align"];
  meta?: CustomColumnMeta;
  format?: "currency" | "string" | "number" | "date" | "percent";
  hiddenColumn?: boolean;
};

export type TableFetchDataFunctionParams = {
  pageIndex: number;
  pageSize: number;
  sortBy: { id: string; desc: boolean }[];
};

export type TableFetchDataFunction = (
  params: TableFetchDataFunctionParams,
) => void;

export type TablePaginationControlled = {
  /** It allows to pass a function to retrieve data from somewhere such as an API. */
  fetchData: TableFetchDataFunction;
  loading: boolean;
  totalRowsCount: number;
};

export interface TableProps<D extends Record<string, unknown>>
  extends Omit<TableOptions<D>, "getCoreRowModel"> {
  columns: TableColumn<D>[];
  footer?: React.ReactNode;
  title?: string | JSX.Element;
  isPaginationHidden?: boolean;
  toolbarButtonProps?: TableToolbarButtonProps;
  /** It will be used to persist the User Settings table state in the local storage. and should be unique from other table persistanceIds */
  persistenceId?: string;
  rowsPerPageOptions?: number[];
  renderExpandedRowSubComponent?: (row: Row<D>) => JSX.Element;
  renderVisibilityRowComponent?: boolean;
  selectorColumnHeader?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRowSelect?: (values: { Rnid: string; Visible: boolean }) => void;
  leftPanel?: JSX.Element;
  topPanel?: JSX.Element;
  headerPanel?: JSX.Element;
  actionsOnLeft?: TableToolbarAction[];
  actionsOnRight?: TableToolbarAction[];
  onAction: (action: TableToolbarAction) => void;
  paginationControlled?: TablePaginationControlled;
  enableMultiSort?: boolean;
  /**  Is used to specify that the table will have sticky header */
  stickyHeader?: boolean;
  /** Specify the height of the table */
  maxHeight?: number;
  /** Is used to specify the initial rows per page */
  initialRowsPerPage?: number;
  /** Allows to reset to zero the page index of the table pagination
   * by toggling the value of this property. */
  pageIndexResetSignal?: boolean;
  noDataComponent?: JSX.Element;
  exportData?: (columns: ColumnDef<D>[]) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDataPerPageChange?: (data: any) => void;
  meta?: {
    sort?: boolean;
  };
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Table = <D extends Record<string, unknown>>(options: TableProps<D>) => {
  const {
    title,
    toolbarButtonProps,
    persistenceId,
    enableMultiSort,
    rowsPerPageOptions = ROWS_PER_PAGE_OPTIONS_DEFAULT,
    renderExpandedRowSubComponent,
    renderVisibilityRowComponent,
    selectorColumnHeader,
    onRowSelect,
    leftPanel,
    topPanel,
    headerPanel,
    actionsOnLeft,
    actionsOnRight,
    isPaginationHidden,
    onAction,
    exportData,
    onDataPerPageChange,
    columns,
    data,
    noDataComponent,
    paginationControlled,
    stickyHeader = false,
    maxHeight,
    initialRowsPerPage = INITIAL_ROWS_PER_PAGE.DEFAULT,
    pageIndexResetSignal,
    ...tableProps
  } = options;

  const classes = SC.useTableStyles();
  const [showFilter, setShowFilter] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const tableId = `table-gm-${persistenceId}`;
  const [actionStates, setActionStates] = React.useState<
    Record<TableToolbarAction, boolean | undefined>
  >({
    "fullscreen-expand": false,
    "filter-results": false,
    "export-to-excel-sheet/csv": false,
    add: false,
    "hide/show-columns": false,
  });

  const newColumns = React.useMemo(
    () =>
      // eslint-disable-next-line no-nested-ternary
      renderExpandedRowSubComponent
        ? ([expanderCell, ...columns] as TableColumn<D>[])
        : renderVisibilityRowComponent
          ? ([
              getSelectorCell(selectorColumnHeader, onRowSelect),
              ...columns,
            ] as TableColumn<D>[])
          : columns,
    [columns, renderExpandedRowSubComponent, renderVisibilityRowComponent],
  );

  const defaultColumn = React.useMemo(
    () => ({
      minSize: 100,
    }),
    [],
  );

  interface ExtendedColumnMeta<D, T> extends ColumnMeta<D, T> {
    sort?: boolean;
  }

  const defaultSortColumn = React.useMemo(() => {
    const sortColumns: { id: string; desc: boolean }[] = [];
    newColumns.forEach((col) => {
      if ((col.meta as ExtendedColumnMeta<any, any>)?.sort) {
        sortColumns.push({ id: _.toString(col.accessorKey), desc: true });
      }
    });
    return sortColumns;
  }, [newColumns]);

  const storedState = React.useMemo(() => {
    if (persistenceId && typeof Storage !== "undefined") {
      const persistentState = localStorage.getItem(tableId);
      if (persistentState) {
        return JSON.parse(persistentState);
      }
    }
    return null;
  }, [persistenceId, tableId]);

  // No right TS types defined. The next version v8 will be being built
  // with TS natively. https://github.com/tannerlinsley/react-table/issues/3064
  const table = useReactTable({
    columns: newColumns,
    data,
    defaultColumn,
    manualPagination: !!paginationControlled,
    manualSorting: !!paginationControlled,
    enableMultiSort: !enableMultiSort,
    enableSortingRemoval: false,
    pageCount,
    ...tableProps,
    initialState: {
      pagination: {
        pageIndex: 0,
        // @ts-ignore
        pageSize: initialRowsPerPage,
      },
      // @ts-ignore
      pageSize: initialRowsPerPage,
      sortBy: defaultSortColumn,
      ...storedState,
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  const {
    getHeaderGroups,
    getFooterGroups,
    getRowModel,
    getState,
    setPageIndex,
    setPageSize,
    setSorting,
    getAllColumns,
    getColumn,
    setColumnOrder,
    setColumnVisibility,
  } = table;

  const state = getState();
  const allColumns = getAllColumns();
  const {
    pagination: { pageSize, pageIndex },
    sorting,
  } = state;

  // Get Visible Columns
  const visibleColumns = allColumns.filter(
    (columns) => state.columnVisibility[columns.id] !== false,
  );

  const handlerOnChangePage = (newPage: number) => {
    setPageIndex(newPage);
  };

  const handlerOnChangeSortOrder = (columnz: Column<any>) => {
    const toggleDesc =
      columnz.getIsSorted() === false || columnz.getIsSorted() === "asc";

    if (columnz.id === "RtnPeriodYear" || columnz.id === "RtnDt") {
      setSorting([
        { id: "RtnPeriodYear", desc: toggleDesc },
        { id: "RtnDt", desc: toggleDesc },
      ]);
    } else {
      setSorting([{ id: columnz.id, desc: toggleDesc }]);
    }
  };

  const handlerOnChangeRowsPerPage = (newRowsPerPage: number) => {
    setPageSize(newRowsPerPage);
  };

  const onActionExtract = (action: TableToolbarAction) => {
    switch (action) {
      case "filter-results":
        setShowFilter(!showFilter);
        break;
      case "hide/show-columns":
        setModal(!modal);
        break;
      case "export-to-excel-sheet/csv":
        if (exportData) {
          exportData(visibleColumns);
        } else {
          // handleExportToCSV(data, columns);
        }

        break;
      case "fullscreen-expand":
        setActionStates((prevState) => ({
          ...prevState,
          "fullscreen-expand": !prevState["fullscreen-expand"],
        }));
        break;
      default:
        onAction(action);
    }
  };

  const toggleModal = useCallback(() => {
    setModal((prevModal) => !prevModal);
  }, []);
  const handleSortColumns = useCallback(
    (orderIds: string[], hiddenIds: string[]) => {
      setColumnOrder(orderIds);
      const columnVisibility = allColumns.reduce(
        (acc, column) => {
          acc[column.id] = !hiddenIds.includes(column.id);
          return acc;
        },
        {} as Record<string, boolean>,
      );

      setColumnVisibility(columnVisibility);
      setModal((modal) => !modal);
    },
    [],
  );

  React.useEffect(() => {
    if (!storedState) {
      const hiddenColumns = newColumns.reduce(
        (acc: Record<string, boolean>, col: TableColumn<D>) =>
          col.accessorKey && col.hiddenColumn === true
            ? {
                ...acc,
                [_.toString(col.accessorKey)]: false,
              }
            : acc,
        {} as Record<string, boolean>,
      );
      setColumnVisibility(hiddenColumns);
    }
  }, [newColumns, getColumn, setColumnVisibility, storedState]);

  React.useEffect(() => {
    if (persistenceId && typeof Storage !== "undefined") {
      const stateToPersist = _.omit(state, ["pageIndex"]);

      localStorage.setItem(tableId, JSON.stringify(stateToPersist));
    }
  }, [state, persistenceId, tableId, paginationControlled]);

  React.useEffect(() => {
    if (onDataPerPageChange) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data_: any = getRowModel().rows.map((row) => row.original);
      if (data_ && data_.length > 0) {
        onDataPerPageChange(data_);
      }
    }
  }, [getRowModel()]);

  const totalRowsCount = paginationControlled
    ? paginationControlled.totalRowsCount
    : data.length;

  React.useEffect(() => {
    setPageCount(Math.ceil(totalRowsCount / pageSize));
  }, [pageSize, totalRowsCount]);

  const fetchData = useMemo(
    () => debounce(paginationControlled?.fetchData ?? (() => null), 100),
    [paginationControlled?.fetchData],
  );
  const sortBy = useMemo(
    () => sorting.map(({ id, desc }) => ({ id, desc })),
    [sorting],
  );

  React.useEffect(() => {
    fetchData?.({ pageIndex, pageSize, sortBy });
  }, [fetchData, pageIndex, pageSize, sortBy]);

  // Variables used to calculate table container height for sticky header
  const mainContainerRef = React.useRef<HTMLDivElement>(null);
  const tableContainerRef = React.useRef<HTMLDivElement>(null);
  const mainContainerPosition =
    mainContainerRef.current?.getBoundingClientRect();
  const tableContainerPosition =
    tableContainerRef.current?.getBoundingClientRect();
  const mainContainerPositionY = mainContainerPosition?.y ?? 0;
  const tableContainerPositionY = tableContainerPosition?.y ?? 0;
  const windowHeight = useWindowHeight();
  const containerHeight = windowHeight - mainContainerPositionY;
  const tableHeight = windowHeight - tableContainerPositionY;

  return (
    <SC.ExpansionContainer
      expanded={actionStates["fullscreen-expand"]}
      maxWidth={false}
    >
      <SC.Container
        maxWidth={false}
        sx={classes.rootStyles}
        ref={mainContainerRef}
        maxHeight={maxHeight}
      >
        {leftPanel && (
          <SC.LeftPaneBox
            pane={showFilter}
            maxHeight={
              maxHeight ?? (stickyHeader ? containerHeight : undefined)
            }
          >
            <LeftPaneContainer>{leftPanel}</LeftPaneContainer>
          </SC.LeftPaneBox>
        )}

        <SC.RightPaneBox pane={leftPanel && showFilter} maxHeight={maxHeight}>
          <SC.TableContainer>
            {headerPanel && <SC.TopPaneBox>{headerPanel}</SC.TopPaneBox>}

            <TableToolbar
              title={title}
              toolbarButtonProps={toolbarButtonProps}
              rowsCount={totalRowsCount}
              page={pageIndex}
              rowsPerPage={pageSize}
              isPaginationHidden={isPaginationHidden}
              rowsPerPageOptions={rowsPerPageOptions}
              onChangePage={handlerOnChangePage}
              onChangeRowsPerPage={handlerOnChangeRowsPerPage}
              actionsOnLeft={actionsOnLeft}
              actionsOnRight={actionsOnRight}
              onAction={onActionExtract}
              actionsStates={actionStates}
            />

            {topPanel && <SC.TopPaneBox>{topPanel}</SC.TopPaneBox>}

            <SC.Container
              maxWidth={false}
              ref={tableContainerRef}
              maxHeight={
                maxHeight === undefined && stickyHeader
                  ? tableHeight
                  : undefined
              }
              flexgrow={maxHeight === undefined ? undefined : 1}
            >
              <SC.Table stickyHeader={stickyHeader}>
                <SC.TableHead>
                  {getHeaderGroups().map((headerGroup) => (
                    <SC.TableRow key={headerGroup.id}>
                      {headerGroup.headers.map((header) => {
                        const isSorted = header.column.getIsSorted();

                        /* @ts-ignore */
                        const { align } = header.column.columnDef;

                        return (
                          <SC.TableCell
                            key={header.id}
                            align={align as "left" | "right" | "center"}
                          >
                            <SC.TableCellHeader
                              align={align as "left" | "right" | "center"}
                            >
                              <span
                                onClick={() =>
                                  handlerOnChangeSortOrder(header.column)
                                }
                              >
                                <span
                                  {...header.column.getToggleSortingHandler()}
                                >
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext(),
                                  )}
                                </span>
                                {isSorted === false ? undefined : (
                                  <SC.TableSortLabel
                                    IconComponent={ArrowDropDownIcon}
                                    active
                                    direction={isSorted}
                                  />
                                )}
                              </span>
                              {header.column.getCanResize() && (
                                <SC.Resizer
                                  {...header.getResizeHandler()}
                                  style={{ width: header.getSize() }}
                                />
                              )}
                            </SC.TableCellHeader>
                          </SC.TableCell>
                        );
                      })}
                    </SC.TableRow>
                  ))}
                </SC.TableHead>
                {noDataComponent && data.length < 1 ? (
                  <SC.TableBody>
                    <SC.TableRow>
                      <SC.TableCell>{noDataComponent}</SC.TableCell>
                    </SC.TableRow>
                  </SC.TableBody>
                ) : (
                  <SC.TableBody>
                    {getRowModel().rows.map((row: Row<D>) => {
                      return (
                        <React.Fragment key={row.id}>
                          <SC.TableRow>
                            {row.getVisibleCells().map((cell) => {
                              /* @ts-ignore */
                              const { align, format } = cell.column.columnDef;

                              return (
                                <SC.TableCell
                                  key={cell.id}
                                  /* @ts-ignore */
                                  state={state.columnResizing}
                                  cellid={cell.column.id}
                                  align={align as "left" | "right" | "center"}
                                  padding={
                                    cell.column.id === EXPANDER_CELL_ID
                                      ? "none"
                                      : "normal"
                                  }
                                >
                                  {format
                                    ? formatCellData(format, cell)
                                    : flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext(),
                                      )}
                                </SC.TableCell>
                              );
                            })}
                          </SC.TableRow>
                          {renderExpandedRowSubComponent &&
                            row.getIsExpanded() && (
                              <SC.TableRow>
                                <SC.TableCell colSpan={newColumns.length}>
                                  {renderExpandedRowSubComponent(row)}
                                </SC.TableCell>
                              </SC.TableRow>
                            )}
                        </React.Fragment>
                      );
                    })}
                  </SC.TableBody>
                )}

                <SC.TableFooter>
                  {getFooterGroups().map((group) => (
                    <SC.TableRow key={group.id}>
                      {group.headers.map((column) => {
                        /* @ts-ignore */
                        const { align } = column.column.columnDef;

                        return (
                          <SC.TableCell
                            key={column.id}
                            align={align as "left" | "right" | "center"}
                          >
                            {flexRender(
                              column.column.columnDef.footer,
                              column.getContext(),
                            )}
                          </SC.TableCell>
                        );
                      })}
                    </SC.TableRow>
                  ))}
                </SC.TableFooter>
              </SC.Table>
            </SC.Container>
          </SC.TableContainer>
        </SC.RightPaneBox>

        <TransferList
          columns={allColumns}
          visibleColumns={visibleColumns}
          open={modal}
          onClose={toggleModal}
          onSortColumns={handleSortColumns}
        />
      </SC.Container>
    </SC.ExpansionContainer>
  );
};

export default Table;
