/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
// Dependencies
import {
  PermissionData,
  PermissionInput,
  RoleData,
  PermissionType,
} from "graphql/types-and-hooks";
import React from "react";

// Components
import Pane from "components/pane/pane.component";
import TextField from "components/text-field/text-field.component";
import DateTimeField from "components/datetime-field/datetime-field.component";
import PermissionDetails from "components/permission-details/permission-details.component";
import Checkbox from "@mui/material/Checkbox";
import {
  useAppPermissionValidator,
  AppPermissionValidator,
  APP_PERMISSION,
} from "components/app-permission-validator/app-permission-validator.component";

// Utils
import { createEmptyRoleData } from "graphql/rbac.utils";
import { Maybe } from "graphql/jsutils/Maybe";

// Assets
import SC from "./role-form-fields.styles";

const DEFAULT_PERMISSION_TYPE = PermissionType.Application;

const visibilityLevelToEnable = (level: Maybe<number>) => level === 0;

const visibilityEnableToLevel = (enable: boolean) => (enable ? 0 : 1);
export interface RoleFormFieldsProps {
  roleData: RoleData;
  permissionList: PermissionData[];
  arePermissionsLoading: boolean;
  isEditable: boolean;
  resetData: boolean;
  onRoleChange: (roleData: RoleData) => void;
  onPermissionsChange: (
    permissionInput: PermissionInput[],
    permissionType: PermissionType,
  ) => void;
  cleanPermissionsChangedList: boolean;
}

export const RoleFormFields: React.FC<RoleFormFieldsProps> = ({
  roleData,
  permissionList,
  arePermissionsLoading,
  isEditable,
  resetData,
  onRoleChange,
  onPermissionsChange,
  cleanPermissionsChangedList,
}) => {
  const [permissions, setPermissions] = React.useState<PermissionData[]>([]);
  const [enableVisibility, setEnableVisibility] = React.useState(() =>
    visibilityLevelToEnable(roleData.visibleLevel),
  );

  const [permissionsData, permissionsApplication] = React.useMemo(() => {
    const permsData: PermissionData[] = [];
    const permsApp: PermissionData[] = [];
    permissions?.forEach((opt) => {
      if (opt.type === PermissionType.Data) permsData.push(opt);
      else permsApp.push(opt);
    });
    return [permsData, permsApp];
  }, [permissions]);

  const [permissionTypeActive, setPermissionTypeActive] = React.useState(
    DEFAULT_PERMISSION_TYPE,
  );

  React.useEffect(() => {
    setPermissions(permissionList);
  }, [permissionList]);

  React.useEffect(() => {
    if (resetData) {
      setPermissions(permissionList);
    }
  }, [resetData, permissionList]);

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = event.target;
    const newRoleData = { ...roleData };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    newRoleData[name] = value;
    onRoleChange(newRoleData);
  };

  const handleRadioOption = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setPermissionTypeActive(event.target.value as PermissionType);
    },
    [],
  );

  const handleParentPermissionsChange = (
    parentPermissionsList: PermissionData[],
    permissionType: PermissionType,
  ) => {
    setPermissions((oldPermissions) => [
      ...oldPermissions.filter((perm) => perm.type !== permissionType),
      ...parentPermissionsList,
    ]);
  };

  const handlePermissions = (
    permissionsObject: PermissionData[],
    parent: string,
  ) => {
    const optionIndex = permissions.findIndex((opt) => opt.id === parent);
    const newPermissions = [...permissions];

    if (optionIndex > -1) {
      newPermissions[optionIndex] = {
        ...newPermissions[optionIndex],
        children: permissionsObject,
      };
    }

    const newRoleData = roleData ? { ...roleData } : createEmptyRoleData();
    newRoleData.permissions = newPermissions;

    setPermissions(newPermissions);
    onRoleChange(newRoleData);
  };

  const handleVisibilityStatus = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    setEnableVisibility(checked);
    const newRoleData = {
      ...roleData,
      visibleLevel: visibilityEnableToLevel(checked),
    };
    onRoleChange(newRoleData);
  };

  const appPermissionValidator = useAppPermissionValidator();

  console.log("permission data ", permissionsData);

  return (
    <Pane>
      <SC.Grid container spacing={2} MarginTop={0}>
        <SC.Grid item xs={6}>
          <SC.Box disabledMarginTop>
            <TextField
              name="name"
              label="Name"
              autoComplete="off"
              value={roleData?.name}
              fullWidth
              required
              disabled={!isEditable}
              onChange={handleTextFieldChange}
            />
          </SC.Box>
        </SC.Grid>
        <SC.Grid item xs={6}>
          <SC.Box disabledMarginTop>
            <DateTimeField
              value={roleData?.creationDate}
              label="Creation Date"
              disabled
              fullWidth
              required
              onChange={() => {}}
            />
          </SC.Box>
        </SC.Grid>
      </SC.Grid>
      <SC.Grid container spacing={2}>
        <SC.Grid item xs={12}>
          <SC.DescriptionBox>
            <TextField
              name="description"
              label="Description"
              value={roleData?.description}
              fullWidth
              multiline
              rows={3}
              maxRows={3}
              disabled={!isEditable}
              onChange={handleTextFieldChange}
            />
          </SC.DescriptionBox>
        </SC.Grid>
      </SC.Grid>
      <AppPermissionValidator
        appPermission={APP_PERMISSION.ADMIN_SET_VISIBILITY_LEVEL}
      >
        <SC.Grid container spacing={2}>
          <SC.Grid item MarginTop={0}>
            <SC.InputLabel>Enable Visibility</SC.InputLabel>
          </SC.Grid>
          <SC.Grid item xs={1} MarginTop={0}>
            <Checkbox
              checked={enableVisibility}
              onChange={handleVisibilityStatus}
              disabled={!isEditable}
            />
          </SC.Grid>
        </SC.Grid>
      </AppPermissionValidator>
      <SC.Grid container spacing={2} scrollable>
        <SC.Grid item MarginTop={0}>
          <SC.InputLabel>Permissions</SC.InputLabel>
        </SC.Grid>
        <SC.Grid item xs={6} MarginTop={0}>
          <SC.RadioGroup row defaultValue={DEFAULT_PERMISSION_TYPE}>
            <SC.FormControlLabel
              value={PermissionType.Application}
              control={
                <SC.Radio disabled={!isEditable} onChange={handleRadioOption} />
              }
              label="App Permissions"
            />
            <AppPermissionValidator
              appPermission={APP_PERMISSION.ADMIN_VIEW_DATA_PERMISSIONS}
            >
              <SC.FormControlLabel
                value={PermissionType.Data}
                control={
                  <SC.Radio
                    disabled={!isEditable}
                    onChange={handleRadioOption}
                  />
                }
                label="Data Permissions"
              />
            </AppPermissionValidator>
          </SC.RadioGroup>
        </SC.Grid>
      </SC.Grid>
      {(permissionTypeActive === PermissionType.Application ||
        permissionTypeActive === PermissionType.Data) && (
        <SC.Grid container spacing={2} scrollable MarginTop={0}>
          <SC.Grid item xs={12} MarginTop={0}>
            <SC.Box disabledMarginTop position="relative">
              <PermissionDetails
                key={permissionTypeActive}
                permissions={
                  permissionTypeActive === PermissionType.Application
                    ? permissionsApplication
                    : permissionsData
                }
                isEditable={
                  isEditable &&
                  (permissionTypeActive === PermissionType.Application ||
                    (permissionTypeActive === PermissionType.Data &&
                      !!appPermissionValidator?.(
                        APP_PERMISSION.ADMIN_EDIT_DATA_PERMISSIONS,
                      )))
                }
                permissionType={permissionTypeActive}
                resetData={resetData}
                onChange={handlePermissions}
                onParentPermissionsChange={handleParentPermissionsChange}
                onPermissionsChange={onPermissionsChange}
                cleanPermissionsChangedList={cleanPermissionsChangedList}
              />
              {arePermissionsLoading && (
                <SC.ProgressIndicatorWrapper>
                  <SC.ProgressIndicator />
                  <br />
                  Loading permissions...
                </SC.ProgressIndicatorWrapper>
              )}
            </SC.Box>
          </SC.Grid>
        </SC.Grid>
      )}
    </Pane>
  );
};

export default RoleFormFields;
