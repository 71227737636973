import React, { useState, useEffect } from "react";

import {} from "graphql/hasura/types-and-hooks";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import AWS from "aws-sdk";
import SC from "./image-list.styles";

const ImageList: React.FC<{
  image_src: string;
  load: boolean;
}> = ({ image_src }) => {
  const [images, setImages] = useState<string[]>([]);
  const [imageError, setImageError] = useState(false);
  const [imagesLoading, setimagesLoading] = useState(true);

  useEffect(() => {
    const fetchImage = async () => {
      let formattedImgeSrc = image_src.replace(",", ", ");
      const s3 = new AWS.S3({
        accessKeyId: "AKIA6QLOECYD2MQGBIUE",
        secretAccessKey: "RvAdktM8kjKytMBy7+TAQpjx3hdv4OV5AXlsS58+",
        region: "af-south-1",
      });

      const params = {
        Bucket: "rtcs-gm-dev",
        Key: `images/StreetView/${formattedImgeSrc}.jpg`,
      };

      try {
        const url = s3.getSignedUrl("getObject", { ...params, Expires: 60 }); // URL valid for 60 seconds
        setImages([url]);
      } catch (error) {
        console.error("Error fetching image URL:", error);
      }
    };
    fetchImage();
  }, [image_src]);

  const onLoadImage = () => {
    setimagesLoading(false);
  };

  const handleImageError = () => {
    setImageError(true);
    setimagesLoading(false);
  };

  return (images && images.length < 1) || imageError ? (
    <>
      <ProgressIndicator open={imagesLoading} />
      <SC.MainImage rowHeight={100} gap={1} cols={4}>
        <SC.GridListTile cols={4} rows={3}>
          <SC.ImagePlaceholder />
        </SC.GridListTile>
      </SC.MainImage>
    </>
  ) : (
    <>
      <SC.Carousel showArrows>
        {images?.map((image, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <SC.Box key={index}>
            <img
              alt="img"
              src={image}
              onLoad={onLoadImage}
              onError={handleImageError}
            />
          </SC.Box>
        ))}
      </SC.Carousel>
      <ProgressIndicator open={imagesLoading} />
    </>
  );
};

export default ImageList;
