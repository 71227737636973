/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";

import { Box as BoxMui } from "@mui/material";

import { styled } from "@mui/material/styles";

const TreeViewMui = styled(SimpleTreeView)({
  flexGrow: 1,
  maxWidth: "400px",
});

const Box = styled(BoxMui)(({ theme }) => ({
  height: "300px",
  overflowY: "auto",
  marginTop: theme.spacing(1),
}));

export default {
  TreeViewMui,
  Box,
};
