import React from "react";

// Assets
import logoUganda from "assets/images/URA_Logo.png";
import SC from "./landing-page.styles";

export interface LandingPageProps {
  children: JSX.Element;
}

const LandingPage: React.FC<LandingPageProps> = ({ children }) => {
  return (
    <SC.Container fixed>
      <SC.GridParent container>
        <SC.Grid item xs={5}>
          <SC.Paper>
            <SC.ImageBox>
              <SC.CardMedia image={logoUganda} />
            </SC.ImageBox>
            <SC.Title>Rental Tax Compliance System</SC.Title>
            <SC.Grid container>
              <SC.Grid item xs={8}>
                {children}
              </SC.Grid>
            </SC.Grid>
            <SC.TermsBox>
              <SC.LinkText>Terms of Service</SC.LinkText>
            </SC.TermsBox>
          </SC.Paper>
        </SC.Grid>
      </SC.GridParent>
    </SC.Container>
  );
};

export default LandingPage;
