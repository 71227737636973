// Dependencies
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { BASE_FONT_SIZE, adaptativeSmallScreen } from "./theme.utils";
import "components/theme-provider/themeAugmentation";
import { color, height } from "@mui/system";

enum FontSizes {
  DEFAULT = "1rem",
  SMALL = "1.14rem",
  MEDIUM = "1.4286rem",
  LARGE = "1.71rem",
}

enum DefaultColors {
  primary = "#00589c",
}

const theme = responsiveFontSizes(
  createTheme({
    typography: {
      fontFamily: "Poppins",
    },
    palette: {
      primary: {
        main: DefaultColors.primary,
        contrastText: "#ffffff",
      },
      secondary: {
        main: "#ffffff",
        contrastText: "#226fa9",
      },
      error: {
        main: "#c32f4f",
      },
      background: {
        default: "#ffffff",
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          html {
            font-size: ${BASE_FONT_SIZE};
          },
          body: {
            fontSize: "1rem",
          },
        `,
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: "black",
            padding: "12px",
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          colorSecondary: {
            color: "black",
            "&$checked": {
              color: "black",
            },
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          colorSecondary: {
            color: "black",
            "&$checked": {
              color: "black",
            },
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          filledSuccess: {
            backgroundColor: "#e9f6e8",
            color: "#22211f",
            fontSize: "1rem",
            textAlign: "left",
            fontWeight: 400,
            lineHeight: "1.5",
            "& .MuiAlert-action": {
              color: "#c2b33b",
            },
          },
          filledWarning: {
            backgroundColor: "#fcfae8",
            color: "#22211f",
            textAlign: "left",
            fontSize: "1rem",
            fontWeight: 400,
            lineHeight: "1.5",
            "& .MuiAlert-action": {
              color: "#c2b33b",
            },
          },
          filledError: {
            backgroundColor: "#f8e3e3",
            color: "#22211f",
            textAlign: "left",
            fontSize: "1rem",
            fontWeight: 400,
            lineHeight: "1.5",
            "& .MuiAlert-action": {
              color: "#cb302f",
            },
          },
          action: {
            alignItems: "start",
          },
        },
      },
    },
    formats: {
      date: "dd/MM/yyyy",
      datetime: "dd/MM/yyyy HH:mm",
    },
    textField: {
      labelTextColor: DefaultColors.primary,
      input: {
        textColor: "#747474",
        backgroundColor: "#ffffff",
        disabledBackgroundColor: "transparent",
        hoverBackgroundColor: "#ebebeb",
      },
      inputUnderline: {
        borderColor: DefaultColors.primary,
        disabledBorderColor: "#fbfbfb",
        errorBorderColor: "#a80500",
      },
      helperTextColor: "#8940f2",
    },
    universalFilter: {
      color: "#22211f",
    },
    taxHistory: {
      padding: "1.14rem",
      accordion: {
        borderBottom: 0,
        display: "none",
        margin: "auto",
        boxShadow: "none",
      },
      header: {
        flexDirection: "row-reverse",
        padding: 0,
        alignItems: "flex-start",
        textAlign: "left",
        content: {
          paddingLeft: "1.14rem",
          paddingTop: ".7rem",
        },
        expandIcon: {
          padding: "1.14rem",
        },
        expanded: {
          margin: 0,
        },
      },
      content: {
        paddingTop: 0,
        paddingLeft: "4rem",
        textAlign: "left",
        height: "60vh",
        overflow: "scroll",
        root: {
          padding: 0,
          marginTop: 0,
          paddingRight: "3rem",
        },
      },
      financialSummary: {
        header: {
          fontSize: FontSizes.MEDIUM,
          fontWeight: "bold",
          textAlign: "right",
          paddingRight: "2.28rem",
        },
        subHeader: {
          fontSize: FontSizes.DEFAULT,
          fontWeight: "bold",
        },
      },
    },
    nestedListItem: {
      fontSize: FontSizes.DEFAULT,
    },
    searchBox: {
      labelTextColor: "#3b4d61",
      input: {
        textColor: "#464646",
        border: "#cbd6e2 1px solid",
      },
      inputUnderline: {
        borderColor: "#cbd6e2",
      },
      helperTextColor: "#3b4d61",
      iconColor: "#33485c",
    },
    autofillField: {
      labelTextColor: "#3b4d61",
      input: {
        textColor: "#464646",
        border: "#cbd6e2 1px solid",
      },
      inputUnderline: {
        borderColor: "#cbd6e2",
      },
      helperTextColor: "#3b4d61",
      iconColor: "#33485c",
    },
    pane: {
      backgroundColor: "#f5f5f5",
    },
    table: {
      tableRow: {
        evenRowBackgroundColor: "rgba(0,0,0,0.04)",
      },
      tablePagination: {
        inputBorder: "#D2DCE6 1px solid",
        selectIconColor: "#88B1D1",
        actionsIconColor: "#4581AF",
      },
      toolbarActions: {
        iconColor: "#4581AF",
        fontSize: FontSizes.LARGE,
      },
      toolbar: {
        fontSize: FontSizes.SMALL,
        fontWeight: "bold",
      },
      head: {
        fontSize: FontSizes.SMALL,
        fontWeight: "bold",
      },
      body: {
        fontSize: FontSizes.DEFAULT,
      },
      footer: {
        fontSize: FontSizes.SMALL,
      },
      link: {
        textDecoration: "none",
        color: "#025A9D",
      },
      icon: {
        default: {
          color: "#cbd6e2",
        },
      },
      IconButton: {
        padding: 0,
        paddindWithLef: "0 0 0 12px",
      },
      noRecordsStatement: {
        textAlign: "left",
      },
      tableRangeActions: {
        fontSize: FontSizes.MEDIUM,
        fontWeight: "bold",
      },
    },
    userProfilePicture: {
      width: 200,
      height: 200,
      defaultColor: "#2e3f50",
    },
    balanceSheet: {
      listItem: {
        borderBottom: "0.03rem solid rgba(0, 0, 0, 0.12)",
        paddingTop: "0.28rem",
        paddingBottom: "0.28rem",
      },
      listText: {
        lineHeight: "1.71rem",
      },
      ListItemSecondaryAction: {
        right: 0,
      },
    },
    popOverButton: {
      backgroundColor: "#f5f8fa",
    },
    progressIndicator: {
      backgroundColor: "rgba(0,0,0,.1)",
    },
    accordionHeader: {
      mainHeader: {
        fontSize: FontSizes.LARGE,
      },
      groupHeader: {
        fontSize: FontSizes.SMALL,
      },
    },
    accordionBody: {
      backgroundColor: "#F5F8FA",
      title: {
        fontSize: FontSizes.MEDIUM,
        fontWeight: "bold",
      },
      subtitle: {
        fontSize: FontSizes.SMALL,
        fontWeight: "bold",
      },
      labelButton: {
        fontSize: "0.ß8rem",
      },
    },
    breadCrumps: {
      fontSize: FontSizes.SMALL,
    },
    switch: {
      fontSize: FontSizes.SMALL,
    },
    addresses: {
      title: {
        fontSize: FontSizes.SMALL,
        fontWeight: "bold",
        color: "",
      },
      body: {
        fontSize: FontSizes.DEFAULT,
      },
    },
    labelKeyValue: {
      label: {
        fontSize: FontSizes.DEFAULT,
      },
      value: {
        fontSize: FontSizes.DEFAULT,
      },
    },
    notes: {
      title: {
        fontSize: FontSizes.SMALL,
        fontWeight: "bold",
      },
      body: {
        fontSize: FontSizes.SMALL,
      },
      toolbar: {
        justifyContent: "space-between",
        fontSize: "1.42rem",
        fontWeight: "bold",
        padding: 0,
        minHeight: 0,
      },
      toolbarAction: {
        color: "#4681AF",
        fontSize: "1.42rem",
        marginRight: "1.14rem",
        display: "inline-flex",
        alignItems: "center",
      },
      toolbarActionText: {
        color: "#4681AF",
        fontSize: "1.42rem",
        display: "inline",
      },
      authorName: {
        fontSize: FontSizes.SMALL,
      },
      authorProfilePicture: {
        width: "1.71rem",
        height: "1.71rem",
        defaultColor: "#2e3f50",
      },
      attachments: {
        iconButton: {
          padding: 0,
          marginRight: "1.14rem",
          fontSize: "1.42rem",
        },
        attachment: {
          marginRight: "1.14rem",
        },
      },
      container: {
        marginBottom: "1.14rem",
        content: {
          paddingTop: "1.14rem",
          paddingBottom: "1.14rem",
          paddingRight: "2.28rem",
          paddingLeft: "2.28rem",
          timestamp: {
            marginTop: "1.14rem",
          },
          body: {
            paddingTop: "1.14rem",
          },
        },
      },
      tab: {
        textAlign: "left",
        padding: "1.14rem",

        titleAction: {
          display: "inline-flex",
          alignItems: "center",
        },

        title: {
          marginRight: "1.14rem",
        },
      },
    },
    tab: {
      color: "#33475B",
      fontSize: FontSizes.DEFAULT,
    },
    tabPanel: {
      backgroundColor: "red",
    },
    label: {
      color: "#2E3F50",
    },
    scrollbar: {
      backgroundColor: "#2e3f50",
    },
    selectField: {
      fontSize: "1.1rem",
      helperTextPaddingLeft: "1rem",
    },
    calendarSelectField: {
      fontSize: "1.1rem",
      height: "2.8176rem",
    },
    border: {
      color: "rgb(0,0,0, 0.12)",
    },
    banner: {
      height: "7.85rem",
    },
    bannerSmall: {
      height: adaptativeSmallScreen(7.85),
    },
    bannerGraphic: {
      paddingTop: "0.42rem",
      paddingRight: "3.86rem",
      paddingBottom: "0.42rem",
      paddingLeft: "2.28rem",
    },
    bannerGraphicSmall: {
      paddingTop: adaptativeSmallScreen(0.42),
      paddingRight: adaptativeSmallScreen(3.86),
      paddingBottom: adaptativeSmallScreen(0.42),
      paddingLeft: adaptativeSmallScreen(2.28),
    },
    bannerGraphicText: {
      fontWeight: "normal",
      fontSize: FontSizes.SMALL,
      lineHeight: "1.57rem",
      color: "#F7B500",
    },
    bannerGraphicTextSmall: {
      fontSize: adaptativeSmallScreen(1.14),
      lineHeight: adaptativeSmallScreen(1.57),
    },
    navigation: {
      paddingTop: "1.14rem",
      paddingRight: "1.71rem",
      paddingBottom: "1.14rem",
      paddingLeft: "1.71rem",
      fontWeight: "normal",
      borderTopRightRadius: "0.57rem",
      borderTopLeftRadius: "0.57rem",
      fontSize: FontSizes.MEDIUM,
      lineHeight: "2.14rem",
      background: "rgba(255,255,255,0.2)",
    },
    navigationSmall: {
      paddingTop: adaptativeSmallScreen(1.14),
      paddingRight: adaptativeSmallScreen(1.71),
      paddingBottom: adaptativeSmallScreen(1.14),
      paddingLeft: adaptativeSmallScreen(1.71),
      borderTopRightRadius: adaptativeSmallScreen(0.57),
      borderTopLeftRadius: adaptativeSmallScreen(0.57),
      fontSize: adaptativeSmallScreen(1.4286),
      lineHeight: adaptativeSmallScreen(2.14),
    },
    navigationIcons: {
      fontSize: "2.85rem",
    },
    navigationIconsSmall: {
      fontSize: adaptativeSmallScreen(2.85),
    },
    navigationTabActive: {
      background: "#FFFFFF",
      color: "#2E3F50",
    },
    administrationMenu: {
      paddingTop: "1.14rem",
      paddingRight: "1.71rem",
      paddingBottom: "1.14rem",
      paddingLeft: "1.71rem",
      fontWeight: "normal",
      borderTopRightRadius: "0.57rem",
      borderTopLeftRadius: "0.57rem",
      color: "#FFFFFF",
      background: "rgba(255,255,255,0.2)",
      fontSize: FontSizes.MEDIUM,
      lineHeight: "2.14rem",
    },
    administrationMenuSmall: {
      paddingTop: adaptativeSmallScreen(1.14),
      paddingRight: adaptativeSmallScreen(1.71),
      paddingBottom: adaptativeSmallScreen(1.14),
      paddingLeft: adaptativeSmallScreen(1.71),
      borderTopRightRadius: adaptativeSmallScreen(0.57),
      borderTopLeftRadius: adaptativeSmallScreen(0.57),
      fontSize: adaptativeSmallScreen(1.4286),
      lineHeight: adaptativeSmallScreen(2.14),
    },
    administrationMenuIcon: {
      fontSize: "2.85rem",
    },
    administrationMenuIconSmall: {
      fontSize: adaptativeSmallScreen(2.85),
    },
    generalSearch: {
      paddingTop: "1.85rem",
      paddingBottom: "1.85rem",
      fontSize: "2.85rem",
      width: "23.42rem",
    },
    generalSearchSmall: {
      paddingTop: adaptativeSmallScreen(1.85),
      paddingBottom: adaptativeSmallScreen(1.85),
      fontSize: adaptativeSmallScreen(2.85),
      width: adaptativeSmallScreen(10.42),
    },
    generalSearchActive: {
      color: "#4581af",
      fontSize: "2rem",
    },
    generalSearchActiveSmall: {
      fontSize: adaptativeSmallScreen(2),
    },
    defaultUserIcon: {
      fontSize: "2.85rem",
    },
    defaultUserIconSmall: {
      fontSize: adaptativeSmallScreen(2.85),
    },
    userMenu: {
      paddingRight: "2.28rem",
      fontSize: "2.46rem",
      width: "23.42rem",
    },
    userMenuSmall: {
      paddingRight: adaptativeSmallScreen(2.28),
      fontSize: adaptativeSmallScreen(2.46),
      width: adaptativeSmallScreen(23.42),
    },
    leftPane: {
      fontSize: "1.5rem",
    },
    iconButton: {
      fontSize: "0.8rem",
    },
    expandAllButton: {
      fontSize: "0.7rem",
      fontWeight: "bold",
    },
    card: {
      title: {
        fontSize: FontSizes.MEDIUM,
        fontWeight: "bold",
      },
      subtitle: {
        fontSize: FontSizes.SMALL,
        fontWeight: "bold",
      },
    },
    selectableList: {
      hoverColor: "rgba(0, 0, 0, 0.04)",
      hoverColorSelected: "rgba(2, 90, 157, 0.95)",
      textColor: "#FFFFFF",
      isSelected: "rgba(2, 90, 157, 0.87)",
      paddingTopTreeItem: 0,
      paddingBottomTreeItem: 0,
      afterBorderLeft: "2rem solid",
      afterBorderTop: "1.70rem solid transparent",
      afterBorderBottom: "1.70rem solid transparent",
    },

    InputLabel: {
      textAlign: "left",
      position: "absolute",
      marginLeft: "35px",
      marginTop: "-15px",
    },

    propertiesTab: {
      container: {
        paddingBottom: "1.14rem",
      },
    },

    propertyDetails: {
      title: {
        fontSize: FontSizes.MEDIUM,
        fontWeight: "bold",
      },

      item: {
        marginBottom: "1px solid #eee",
      },
    },

    rMatchScore: {
      fontSize: "1.14rem",
      fontWeight: "bold",
      value: {
        color: "#30A216",
      },
    },

    reportCard: {
      padding: FontSizes.SMALL,
      border: "0.07rem solid rgba(0,0,0, 0.12)",
      fontSize: FontSizes.MEDIUM,
      color: "#025A9D",
      textColor: "#000",
    },

    reports: {
      title: {
        fontWeight: "bold",
        fontSize: "1.71rem",
        paddingRight: "1rem",
        textAlign: "left",
        marginRight: "1rem",
        opacity: 0.8,
      },
      description: {
        paddingLeft: "1rem",
        paddingRight: "1rem",
        paddingTop: "1.14rem",
        paddingBottom: "1.14rem",
        textAlign: "left",
      },
    },

    lightbox: {
      fade: {
        border: "2px solid #000",
      },
    },

    imagePlaceholder: {
      icon: {
        fontSize: "5rem",
        color: "#ccc",
      },
    },

    imageList: {
      height: "7.14rem",
    },
    radioButton: {
      color: "black",
    },

    UtilitiesTab: {
      header: {
        fontSize: FontSizes.MEDIUM,
        fontWeight: "bold",
      },
    },

    tableFilter: {
      container: {
        paddingLeft: "1.14rem",
        paddingRight: "1.14rem",
        textAlign: "left",
      },
      label: {
        paddingBottom: "2.28rem",
        fontWeight: "bold",
      },
      box: {
        padding: 0,
        paddingTop: "2.28rem",
      },
      boxButton: {
        padding: 0,
        paddingTop: "2.28rem",
        textAlign: "left",
      },
    },
    landingPage: {
      borderRadius: "5px",
      button: {
        height: "1.8rem",
      },
      typography: {
        color: "#025A9D",
        marginTop: "-0.05rem",
        fontSize: "0.75rem",
      },
      eyeIcon: {
        right: "37%",
      },
      termsBox: {
        bottom: "0",
        left: "50%",
      },
      title: {
        color: "#374048",
        fontSize: "1.8rem",
        marginTop: "1.14rem",
        fontFamily: "Poppins",
      },
    },

    analysis: {
      Box: {
        paddingBottom: "48px",
      },

      noAnalysis: {
        marginTop: "20px",
      },
    },

    tableCard: {
      card: {
        borderRadius: "8px",
        margin: "24px",
        border: "1px solid #444",
      },
    },

    revisionPage: {
      title: {
        fontSize: FontSizes.MEDIUM,
        fontWeight: "bold",
        textAlign: "left",
      },
      treeItemIcon: {
        width: "100px",
        justifyContent: "end",
        fontWeight: "bold",
      },
      treeItemLabel: {
        textAlign: "left",
      },
    },
    map: {
      title: {
        fontSize: "1rem",
        textAlign: "left",
        marginLeft: "2rem",
        fontWeight: "bold",
      },
    },
  }),
);

export default theme;
