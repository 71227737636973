/* eslint-disable no-alert */
// Dependencies
import React, { useEffect, useMemo } from "react";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  Query_RootRtcs_Gm_Dev_Streetview_StreetViewAnalysis_AggregateArgs,
  usePaginatedStreetViewQuery,
} from "graphql/hasura/types-and-hooks";
import { ColumnDef } from "@tanstack/react-table";
import { ServerDataToLayer } from "rView/home/utils/layer";

import useExportData from "exports/useExportData";

import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";
import Breadcrumb from "components/breadcrumb/breadcrumb.component";

// Utils
import { useAppErrorHandler } from "errors/app.errors";
import { FilterCondition } from "components/table-filter/table-filter.component";
import {
  getConditionsFromDefaultFilters,
  getHasUnhandledDefaultFilter,
  mergeWithDefaultConditions,
} from "pages/reports/utils";

// Schema
import {
  propertiesTableColumns,
  IndividualRegistrationTableFilters,
  DEFAULT_FILTER_ACCESSOR,
} from "./street-view.schema";

// Assets
import SC from "./street-view.styles";

interface IndividualRegistrationTableProps {
  displayHeader?: boolean;
  defaultFilters?: IndividualRegistrationTableFilters;
  initialRowsPerPage?: number;
  maxHeight?: number;
  noDataComponent?: JSX.Element;
  onLoaded?: () => void;
  title?: string;
}

const TABLE_NAME = "rtcs_db_FnRPT_IndividualRegistration";
const persistenceId = "87164010-277a-4776-9183-3ff4c44a8a4b";

export const IndividualRegistrationTable: React.FC<IndividualRegistrationTableProps> =
  React.memo(({ defaultFilters, onLoaded }) => {
    const [rowsPerPageData, setRowsPerPageData] = React.useState<[]>([]);
    const defaultFilterConditions = useMemo(
      () =>
        getConditionsFromDefaultFilters(
          defaultFilters ?? {},
          DEFAULT_FILTER_ACCESSOR,
        ),
      [defaultFilters],
    );
    const hasUnhandledDefaultFilter = useMemo(
      () =>
        getHasUnhandledDefaultFilter(
          defaultFilters ?? {},
          DEFAULT_FILTER_ACCESSOR,
        ),
      [defaultFilters],
    );
    const [filterConditions, setFilterConditions] = React.useState<
      FilterCondition[]
    >([] as FilterCondition[]);
    const [queryVariables, setQueryVariables] =
      React.useState<Query_RootRtcs_Gm_Dev_Streetview_StreetViewAnalysis_AggregateArgs>(
        {
          where: mergeWithDefaultConditions(
            defaultFilterConditions,
            filterConditions,
          ),
        },
      );

    const { data, loading, error } = usePaginatedStreetViewQuery({
      context: { clientName: CLIENT_NAME.HASURA },
      skip: !queryVariables || hasUnhandledDefaultFilter,
      variables: {
        params: jsonToStringQueryVariables(queryVariables ?? {}),
        aggregateParams: jsonToStringQueryVariables({
          where: queryVariables?.where,
        }),
      },
    });

    const [exportDataFn, { loading: exportLoading, error: exportError }] =
      useExportData(TABLE_NAME, queryVariables);

    useAppErrorHandler(error || exportError);

    const properties = React.useMemo(() => {
      const individual =
        data?.rtcs_gm_dev_Fnstreetview_StreetViewAnalysis ?? [];

      return individual;
    }, [data]);

    useEffect(() => {
      setQueryVariables((oldQueryVariables) => ({
        ...oldQueryVariables,
        where: mergeWithDefaultConditions(
          defaultFilterConditions,
          filterConditions,
        ),
      }));
    }, [defaultFilterConditions, filterConditions]);
    useEffect(() => {
      if (!loading) onLoaded?.();
    }, [loading, onLoaded]);

    return (
      <>
        <SC.SwitchViews
          LeftView={
            <SC.MapTableContainer
              columns={propertiesTableColumns}
              properties={properties}
              persistenceId={persistenceId}
              paginationControlled={undefined}
              initialRowsPerPage={undefined}
              pageIndexResetSignal={undefined}
              stickyHeader
              exportData={(columns: ColumnDef<any, any>[]) =>
                exportDataFn(columns, undefined, properties)
              }
              onDataPerPageChange={(data) => {
                setRowsPerPageData(data);
              }}
            />
          }
          leftViewName="Table"
          RightView={
            <SC.MapContainer properties={ServerDataToLayer(rowsPerPageData)} />
          }
          rightViewName="Map"
        />
        <SC.ProgressIndicator open={exportLoading || loading} />
      </>
    );
  });

const StreetView: React.FC = () => {
  return (
    <SC.Box pl={2} pr={2} pt={2}>
      <Breadcrumb label="Street View" />
      <IndividualRegistrationTable displayHeader />
    </SC.Box>
  );
};

export default StreetView;
